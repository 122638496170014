import { styled } from "@mui/system";
import { Select } from "@mui/material";

const CustomizedSelect = styled((props: any) => <Select {...props} />)(() => ({
  borderRadius: `0`,
  width: "100%",
  "& .MuiOutlinedInput-notchedOutline": {
    border: "none",
  },
}));

export default CustomizedSelect;
