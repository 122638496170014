import { ICategory } from "../../models/ICategory";
import {
  IWorkPreview,
  IWork,
  IWorkBlock,
  IWorkError,
} from "../../models/IWork";
import {
  WorksAction,
  WorksActionTypes,
  WorksState,
} from "../../models/redux/work";

const initialState: WorksState = {
  worksPreview: [] as IWorkPreview[],
  work: {
    id: 0,
    title: "",
    date: null,
    workCost: 0,
    workDuration: "",
    previewImage: "",
    categoryId: 1,
    images: [] as any[],
    blocks: [] as IWorkBlock[],
    types: [] as ICategory[],
    isPreview: false,
  } as IWork,
  isTouchedWork: false,
};

export const workReducer = (
  state = initialState,
  action: WorksAction
): WorksState => {
  switch (action.type) {
    case WorksActionTypes.SET_WORKS_PREVIEW:
      return { ...state, worksPreview: action.payload };

    case WorksActionTypes.SET_WORK:
      return {
        ...state,
        isTouchedWork: initialState.isTouchedWork,
        work: { ...initialState.work, ...action.payload },
      };
    case WorksActionTypes.CHANGE_WORK:
      return {
        ...state,
        work: {
          ...state.work,
          [action.payload.name]: action.payload.value,
        },
      };
    case WorksActionTypes.UPLOAD_WORK_COVER:
      return {
        ...state,
        work: {
          ...state.work,
          previewImage: action.payload,
        },
      };
    case WorksActionTypes.UPLOAD_WORK_IMAGES: {
      const images = state?.work?.images ?? [];
      return {
        ...state,
        work: {
          ...state.work,
          images: [...images, ...action.payload],
        },
      };
    }
    case WorksActionTypes.DELETE_WORK_COVER:
      return { ...state, work: { ...state.work, previewImage: "" } };
    case WorksActionTypes.DELETE_WORK_IMAGE: {
      const imageIndex = action.payload;
      const images = state.work.images.filter(
        (_, index) => index !== imageIndex
      );

      return { ...state, work: { ...state.work, images } };
    }
    case WorksActionTypes.CREATE_WORK_BLOCK: {
      return {
        ...state,
        work: {
          ...state.work,
          blocks: [...state.work.blocks, { type: action.payload }],
        },
      };
    }
    case WorksActionTypes.DELETE_WORK_BLOCK: {
      const deletedBlockIndex = action.payload;
      const newBlocks = state.work.blocks.filter(
        (_, index) => index !== deletedBlockIndex
      );
      return {
        ...state,
        work: { ...state.work, blocks: newBlocks },
      };
    }
    case WorksActionTypes.CHANGE_WORK_BLOCK: {
      const { blockIndex, value } = action.payload;
      const updatedBlocks = state.work.blocks.map((block, index) =>
        index === blockIndex ? { ...block, text: value } : block
      );
      return {
        ...state,
        work: {
          ...state.work,
          blocks: updatedBlocks,
        },
      };
    }
    case WorksActionTypes.CHANGE_WORK_BLOCK_POSITION: {
      const { blockIndex, direction } = action.payload;
      const blocks = [...state.work.blocks];

      if (blockIndex >= 0 && blockIndex < blocks.length) {
        const targetIndex = blockIndex + direction;

        if (targetIndex >= 0 && targetIndex < blocks.length) {
          const temp = blocks[blockIndex];
          blocks[blockIndex] = blocks[targetIndex];
          blocks[targetIndex] = temp;
        }
      }

      return {
        ...state,
        work: { ...state.work, blocks },
      };
    }
    case WorksActionTypes.CREATE_WORK_BLOCK_LIST_ITEM: {
      const blockIndex = action.payload;
      const blocks = state.work.blocks.map((item, index) => {
        if (index === blockIndex) {
          const newItem: IWorkBlock = { ...item };
          if (!newItem["list"]) newItem["list"] = [];
          const newList = [...newItem["list"], ""];

          newItem["list"] = newList;

          return newItem;
        }
        return item;
      });

      return {
        ...state,
        work: { ...state.work, blocks: blocks },
      };
    }
    case WorksActionTypes.DELETE_WORK_BLOCK_LIST_ITEM: {
      const { blockIndex, listItemIndex } = action.payload;
      const blocks = state.work.blocks.map((item, index) => {
        if (index === blockIndex) {
          const newItem: IWorkBlock = { ...item };
          if (!newItem["list"]) newItem["list"] = [];
          const newList = newItem["list"].filter(
            (_, mappbleIndex) => mappbleIndex !== listItemIndex
          );

          newItem["list"] = newList;

          return newItem;
        }
        return item;
      });

      return {
        ...state,
        work: { ...state.work, blocks: blocks },
      };
    }
    case WorksActionTypes.CHANGE_WORK_BLOCK_LIST_ITEM: {
      const { blockIndex, listItemIndex, value } = action.payload;
      const blocks = state.work.blocks.map((item, index) => {
        if (index === blockIndex) {
          const newItem: IWorkBlock = { ...item };
          if (!newItem["list"]) newItem["list"] = [];
          const newList = newItem["list"].map((mappbleItem, mappbleIndex) =>
            mappbleIndex === listItemIndex ? value : mappbleItem
          );

          newItem["list"] = newList;

          return newItem;
        }
        return item;
      });

      return {
        ...state,
        work: { ...state.work, blocks: blocks },
      };
    }

    case WorksActionTypes.CREATE_WORK:
      return {
        ...state,
        work: initialState.work,
        isTouchedWork: initialState.isTouchedWork,
      };
    case WorksActionTypes.TOUCH_WORK:
      return { ...state, isTouchedWork: true };

    case WorksActionTypes.DELETE_WORK:
      return {
        ...state,
        worksPreview: state.worksPreview.filter(
          (work) => Number(work.id) !== Number(action.payload)
        ),
      };
    default:
      return state;
  }
};
