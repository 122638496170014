import { IRegisterData, ILoginData, IUser } from "./../../models/IUser";
import { API_URL } from "./../../http/index";
import { AuthResponse } from "./../../models/response/AuthResponse";
import axios from "axios";
import { UserAction, UserActionTypes } from "./../../models/redux/user";
import { Dispatch } from "redux";
import { localStorageToken } from "../../constants";
import UserService from "../../services/UserService";
import getAccessByRole from "../../hooks/getAccessByRole";
import { ViewAction, ViewActionTypes } from "../../models/redux/view";

export const registerUser = (
  registerData: IRegisterData,
  callback: () => void
) => {
  return async (dispatch: Dispatch<UserAction | ViewAction>) => {
    dispatch({ type: ViewActionTypes.SET_LOADING, payload: true });
    try {
      await UserService.registration(registerData);
      callback();
      dispatch({
        type: ViewActionTypes.SHOW_ALERT,
        payload: {
          isShowAlert: true,
          alertText: "Користувача успішно створено",
          alertType: "success",
        },
      });
    } catch (e: any) {
      const alertText = e?.response?.data?.message ?? "Упс, щось пішло не так!";
      dispatch({
        type: ViewActionTypes.SHOW_ALERT,
        payload: { isShowAlert: true, alertText, alertType: "error" },
      });
    }
    dispatch({ type: ViewActionTypes.SET_LOADING, payload: false });
  };
};

export const loginUser = (loginData: ILoginData) => {
  return async (dispatch: Dispatch<UserAction | ViewAction>) => {
    dispatch({ type: ViewActionTypes.SET_LOADING, payload: true });
    try {
      const response = await UserService.login(loginData);
      const { accessToken, user } = response.data;
      const isAdmin = getAccessByRole(user.role);
      localStorage.setItem(localStorageToken, accessToken);

      dispatch({
        type: UserActionTypes.USER_LOGIN,
        payload: { ...user, isAdmin },
      });
      dispatch({
        type: ViewActionTypes.SHOW_ALERT,
        payload: {
          isShowAlert: true,
          alertText: "Вхід здійснено успішно",
          alertType: "success",
        },
      });
    } catch (e: any) {
      const alertText = e?.response?.data?.message ?? "Упс, щось пішло не так!";
      dispatch({
        type: ViewActionTypes.SHOW_ALERT,
        payload: { isShowAlert: true, alertText, alertType: "error" },
      });
    }
    dispatch({ type: ViewActionTypes.SET_LOADING, payload: false });
  };
};

export const logoutUser = () => {
  return async (dispatch: Dispatch<UserAction | ViewAction>) => {
    dispatch({ type: ViewActionTypes.SET_LOADING, payload: true });
    try {
      await UserService.logout();
      localStorage.removeItem(localStorageToken);

      dispatch({ type: UserActionTypes.USER_LOGOUT });
      dispatch({
        type: ViewActionTypes.SHOW_ALERT,
        payload: {
          isShowAlert: true,
          alertText: "Вихід здійснено успішно",
          alertType: "success",
        },
      });
    } catch (e: any) {
      dispatch({
        type: ViewActionTypes.SHOW_ALERT,
        payload: {
          isShowAlert: true,
          alertText: "Something went wrong",
          alertType: "error",
        },
      });
    }
    dispatch({ type: ViewActionTypes.SET_LOADING, payload: false });
  };
};

export const chechAuth = (token: string | null) => {
  return async (dispatch: Dispatch<UserAction | ViewAction>) => {
    try {
      if (!token) {
        dispatch({ type: UserActionTypes.USER_INIT, payload: {} as IUser });
      } else {
        const response = await axios.get<AuthResponse>(
          `${API_URL}user/refresh`,
          { withCredentials: true }
        );
        const { accessToken, user } = response.data;
        const isAdmin = getAccessByRole(user.role);
        localStorage.setItem(localStorageToken, accessToken);

        dispatch({
          type: UserActionTypes.USER_INIT,
          payload: { ...user, isAdmin },
        });
      }
    } catch (e: any) {
      localStorage.removeItem(localStorageToken);
      dispatch({
        type: ViewActionTypes.SHOW_ALERT,
        payload: {
          isShowAlert: true,
          alertText: "Ваша ссесія застаріла",
          alertType: "error",
        },
      });
    }
    dispatch({ type: ViewActionTypes.REMOVE_INIT_LOADING });
  };
};
