import { useState, useEffect, useMemo } from "react";
import { Box, IconButton, TextField } from "@mui/material";
import { HighlightOff } from "@mui/icons-material/";
import { useActions, useTypedSelector } from "../../hooks";

const InputBox = ({
  label = "",
  type = "text",
  textarea = false,
  value,
  handleChange,
  name,
  endIcon = false,
  onDelete,
  validate,
  isTouched = false,
  disabled = false,
}: {
  label?: string;
  type?: "text" | "number";
  textarea?: boolean;
  value: string | number;
  handleChange: any;
  name: string;
  endIcon?: boolean;
  onDelete?: any;
  validate?: any[];
  isTouched?: boolean;
  disabled?: boolean;
}) => {
  const { isAdmin } = useTypedSelector((state) => state.user);
  const [isError, setIsError] = useState(false);

  const editAccess = useMemo(() => {
    if (!isAdmin) {
      return false;
    }
    return true;
  }, [isAdmin]);

  useEffect(() => {
    if (!isTouched) {
      setIsError(false);
      return;
    }

    if (!validate || validate.length <= 0) return;

    let newIsError = false;

    for (let i = 0; i < validate.length; i++) {
      const item = validate[i];
      const errorTextOrUndefined = item(value);
      if (errorTextOrUndefined) {
        newIsError = errorTextOrUndefined;
        break;
      }
    }

    setIsError(newIsError);
  }, [isTouched, value]);

  if (editAccess)
    return (
      <Box
        sx={{
          display: "flex",
          width: "100%",
          alignItems: "center",
        }}
        color={!!isError ? "error" : "primary"}
      >
        <TextField
          disabled={disabled}
          type={type}
          label={label}
          error={!!isError}
          helperText={!!isError ? isError : ""}
          multiline={textarea}
          sx={{ width: "100%" }}
          onChange={handleChange}
          name={name}
          value={value}
          id="outlined-basic"
          variant="outlined"
        />

        {endIcon && (
          <IconButton
            onClick={onDelete}
            sx={{ ml: "12px" }}
            aria-label="delete"
            size="medium"
            color="error"
          >
            <HighlightOff fontSize="large" />
          </IconButton>
        )}
      </Box>
    );
  return <div>{value}</div>;
};

export default InputBox;
