import {
  ViewAction,
  ViewActionTypes,
  ViewState,
} from "../../models/redux/view";

const initialState: ViewState = {
  isLoading: false,
  isInitLoading: true,
  isLoadingVar: false,
  isShowAlert: false,
  alertType: "info",
  alertText: "",
  isShowModal: false,
  modalText: "",
  modalCallback: () => {},
};

export const viewReducer = (
  state = initialState,
  action: ViewAction
): ViewState => {
  switch (action.type) {
    case ViewActionTypes.SET_LOADING:
      return { ...state, isLoading: action.payload };
    case ViewActionTypes.REMOVE_INIT_LOADING:
      return { ...state, isInitLoading: false };
    case ViewActionTypes.SET_LOADING_VAR:
      return { ...state, isLoadingVar: action.payload };
    case ViewActionTypes.SHOW_ALERT:
      return { ...state, isShowAlert: true, ...action.payload };
    case ViewActionTypes.HIDE_ALERT:
      return { ...state, isShowAlert: false };
    case ViewActionTypes.SHOW_MODAL:
      return { ...state, isShowModal: true, ...action.payload };
    case ViewActionTypes.HIDE_MODAL:
      return { ...state, isShowModal: false };
    default:
      return state;
  }
};
