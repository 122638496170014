import { ILoginData, IRegisterData, IRoles, IUser } from "./../models/IUser";
import { AuthResponse } from "./../models/response/AuthResponse";
import $api from "../http";
import { AxiosResponse } from "axios";

export default class UserService {
  static async getUsers(): Promise<AxiosResponse<IUser[]>> {
    return $api.get<IUser[]>("user/all");
  }
  static async getUserById(id: string): Promise<AxiosResponse<IUser>> {
    return $api.get<IUser>("user/profile/" + id);
  }

  static async login({
    login,
    password,
  }: ILoginData): Promise<AxiosResponse<AuthResponse>> {
    return $api.post<AuthResponse>("user/login", { login, password });
  }
  static async registration({
    login,
    password,
    role,
    name,
  }: IRegisterData): Promise<AxiosResponse<AuthResponse>> {
    return $api.post<AuthResponse>("user/registration", {
      login,
      password,
      role,
      name,
    });
  }
  static async logout(): Promise<void> {
    return $api.post("user/logout");
  }

  static async updateUser(
    userId: number,
    name: string,
    role: IRoles,
    blogsId: number[]
  ): Promise<AxiosResponse> {
    return $api.put("user", { userId, name, role, blogsId });
  }
  static async deleteUser(id: number): Promise<AxiosResponse> {
    return $api.delete("user/" + id);
  }
}
