export const required = (value) => {
  if (value) return undefined;
  return "Це поле обов'язкове";
};

export const maxLengthCreator = (maxLength) => (value) => {
  if (value.length > maxLength)
    return `Максимальна кількість символів '${maxLength}';`;
  return undefined;
};
