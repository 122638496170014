import axios from "axios";
import { AuthResponse } from "../models/response/AuthResponse";

export const API_URL = process.env.REACT_APP_API_URL;
export const API_FILES_URL = process.env.REACT_APP_API_URL + "uploads/";

const $api = axios.create({
  withCredentials: true,
  baseURL: API_URL,
});

$api.interceptors.request.use((config) => {
  config.headers.Authorization = `Bearer ${localStorage.getItem("token")}`;
  return config;
});

let isRefreshing = false;
let failedQueue: any[] = [];

$api.interceptors.response.use(
  (config) => {
    return config;
  },
  async (error) => {
    const originalRequest = error.config;
    if (error.response.status === 401 && !originalRequest._isRetry) {
      if (!isRefreshing) {
        isRefreshing = true;
        try {
          const response = await axios.get<AuthResponse>(
            `${API_URL}user/refresh`,
            { withCredentials: true }
          );
          localStorage.setItem("token", response.data.accessToken);
          isRefreshing = false;
          $api.defaults.headers.common[
            "Authorization"
          ] = `Bearer ${response.data.accessToken}`;
          failedQueue.forEach((prom) => prom.resolve());
          failedQueue = [];
          return $api.request(originalRequest);
        } catch (e) {
          localStorage.removeItem("token");
          isRefreshing = false;
          failedQueue.forEach((prom) => prom.reject(e));
          failedQueue = [];
          console.log("Token refresh failed:", e);
          // Handle logout or other actions as needed
        }
      }
      const retryPromise = new Promise((resolve, reject) => {
        failedQueue.push({ resolve, reject });
      });
      originalRequest._isRetry = true;
      return retryPromise;
    }
    return Promise.reject(error);
  }
);

export default $api;
