import { useMemo } from "react";
import { Autocomplete, TextField } from "@mui/material";
import { useTypedSelector } from "../../hooks";

const InputSelectMultiple = ({
  value,
  label = "",
  onChange,
  list,
}: {
  value: any[];
  label?: string;
  onChange: any;
  list: any[];
}) => {
  const { isAdmin } = useTypedSelector((state) => state.user);
  const editAccess = useMemo(() => {
    if (!isAdmin) {
      return false;
    }
    return true;
  }, [isAdmin]);

  if (!editAccess) return <div>selects</div>;

  return (
    <Autocomplete
      multiple
      value={value}
      onChange={(_, newValue) => onChange(newValue)}
      limitTags={1}
      options={list}
      disableCloseOnSelect
      getOptionLabel={(option) => option.title}
      renderOption={(props, option) => <li {...props}>{option.title}</li>}
      renderInput={(params) => <TextField {...params} label={label} />}
      isOptionEqualToValue={(option, value) => option.id === value.id}
    />
  );
};

export default InputSelectMultiple;
