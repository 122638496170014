import { useContext, useMemo } from "react";
import { useNavigate } from "react-router-dom";

import { FlexBetween, UserMenu } from "../";
import {
  LightModeOutlined,
  DarkModeOutlined,
  Menu as MenuIcon,
} from "@mui/icons-material";

import {
  Box,
  Button,
  AppBar,
  IconButton,
  Toolbar,
  useTheme,
} from "@mui/material";

import { useTypedSelector } from "../../hooks";
import { ColorModeContext } from "../../App";

const Header = ({ isSidebarOpen, setIsSidebarOpen }: any) => {
  const { id } = useTypedSelector((state) => state.user);
  const isAuth = useMemo(() => !!id, [id]);
  const colorMode = useContext(ColorModeContext);

  const theme: any = useTheme();

  return (
    <AppBar
      sx={{
        position: "static",
        background: "none",
        boxShadow: "none",
      }}
    >
      <Toolbar sx={{ justifyContent: "space-between" }}>
        {/* LEFT SIDE */}
        {isAuth ? (
          <IconButton onClick={() => setIsSidebarOpen(!isSidebarOpen)}>
            <MenuIcon fontSize="large" />
          </IconButton>
        ) : (
          <div></div>
        )}

        {/* RIGHT SIDE */}
        <FlexBetween gap="1.5rem">
          <IconButton onClick={() => colorMode.toggleColorMode()}>
            {theme.palette.mode === "dark" ? (
              <DarkModeOutlined sx={{ fontSize: "25px" }} />
            ) : (
              <LightModeOutlined sx={{ fontSize: "25px" }} />
            )}
          </IconButton>

          {isAuth && (
            <>
              <UserMenu />
            </>
          )}
        </FlexBetween>
      </Toolbar>
    </AppBar>
  );
};

export default Header;
