import { IRoute } from "../models/IRoutes";
import { IRoles } from "../models/IUser";

const isProtected = (layers:IRoute, isAdmin:boolean, role:IRoles) => {
    
    const { 
        isAdminRequire, 
        accessRolesWithAdmins,
        accessRoles 
    } = layers

    if (isAdminRequire && !isAdmin){
        return false;
    }
    
    if (
        accessRolesWithAdmins &&
        !accessRolesWithAdmins.includes(role) &&
        !isAdmin
    ) {
        return false;
    }
    
    if (accessRoles && !accessRoles.includes(role) && role !== IRoles.GOD) {
        return false;
    }

    return true;
}

export default isProtected;