import { ReactElement } from "react";
import { isProtected, useTypedSelector } from "../../hooks";
import { IRoute } from "../../models/IRoutes";
import { Navigate, Outlet } from "react-router-dom";

const ProtectedRoute = ({
  route,
  redirectPath = "/people",
  children,
}: {
  route: IRoute;
  redirectPath?: string;
  children?: ReactElement;
}) => {
  const { isAdmin, role } = useTypedSelector((state) => state.user);
  const isAllowed = isProtected(route, isAdmin, role);
  if (!isAllowed) return <Navigate to={redirectPath} replace />;

  return children ? children : <Outlet />;
};

export default ProtectedRoute;
