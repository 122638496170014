import $api from "../http";
import { AxiosResponse } from "axios";
import { ICategory } from "../models/ICategory";

export default class CategoryService {
  static async getCategories(): Promise<AxiosResponse<ICategory[]>> {
    return $api.get<ICategory[]>("categories");
  }
  static async getMainCategories(): Promise<AxiosResponse<ICategory[]>> {
    return $api.get<ICategory[]>("categories/main");
  }
}
