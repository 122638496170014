import { useEffect, useState } from "react";

import { Box, useTheme } from "@mui/material";
import {
  DataGrid,
  GridActionsCellItem,
  GridRowModesModel,
  GridRowModes,
  GridRowId,
  GridRowModel,
  GridColDef,
  GridRenderEditCellParams,
  GridRenderCellParams,
  GridEventListener,
  GridCellParams,
  GridRowEditStopReasons,
  useGridApiContext,
  ukUA,
} from "@mui/x-data-grid";

import { Cancel, Save, Edit, Delete } from "@mui/icons-material";
import { Title, CustomSelect } from "../../components";

import UserService from "../../services/UserService";
import { IRoles, IUser } from "../../models/IUser";
import {
  getAccessByRole,
  getAccessRolesByRole,
  useActions,
  useTypedSelector,
} from "../../hooks";

const People = () => {
  const { setLoading, showModal } = useActions();
  const theme: any = useTheme();
  const [users, setUsers] = useState([] as IUser[]);
  const [rowModesModel, setRowModesModel] = useState<GridRowModesModel>({});
  const { id, isAdmin, role } = useTypedSelector((state) => state.user);

  const handleRowEditStop: GridEventListener<"rowEditStop"> = (
    params,
    event
  ) => {
    if (params.reason === GridRowEditStopReasons.rowFocusOut) {
      event.defaultMuiPrevented = true;
    }
  };

  const handleEditClick = (id: GridRowId) => () => {
    setRowModesModel({ ...rowModesModel, [id]: { mode: GridRowModes.Edit } });
  };

  const handleSaveClick = (id: GridRowId) => () => {
    setRowModesModel({ ...rowModesModel, [id]: { mode: GridRowModes.View } });
  };

  const handleDeleteClick = (id: GridRowId) => () => {
    showModal("Ви дійсно бажаєте видалити користувача?", async () => {
      await UserService.deleteUser(Number(id));
      setUsers(users.filter((user) => user.id !== id));
    });
  };

  const handleCancelClick = (id: GridRowId) => () => {
    setRowModesModel({
      ...rowModesModel,
      [id]: { mode: GridRowModes.View, ignoreModifications: true },
    });
  };

  const processRowUpdate = async (newRow: GridRowModel & IUser) => {
    const updatedRow = { ...newRow };

    await UserService.updateUser(
      Number(newRow.id),
      newRow.name,
      newRow.role,
      newRow.blogAccess
    );
    setUsers(
      users.map((user) => (user.id === updatedRow.id ? updatedRow : user))
    );
    return updatedRow;
  };

  const handleRowModesModelChange = (newRowModesModel: GridRowModesModel) => {
    setRowModesModel(newRowModesModel);
  };

  const UserRoleInputCell = (props: GridRenderCellParams<any, string>) => {
    const { id, value, field } = props;
    const apiRef = useGridApiContext();
    const initValue = value || "";
    const roles = getAccessRolesByRole(role);

    return (
      <CustomSelect
        value={initValue}
        callback={(value: any) =>
          apiRef.current.setEditCellValue({ id, field, value })
        }
        list={roles}
      />
    );
  };

  const columns: GridColDef[] = [
    {
      field: "id",
      headerName: "id",
      sortable: false,
      width: 60,
    },
    {
      field: "name",
      headerName: "Ім'я",
      width: 200,
      sortable: false,
      editable: true,
    },
    {
      field: "role",
      headerName: "Роль",
      width: 200,
      editable: true,
      renderEditCell: (params: GridRenderEditCellParams) => (
        <UserRoleInputCell {...params} />
      ),
    },
  ];

  if (isAdmin) {
    columns.push({
      field: "actions",
      type: "actions",
      headerName: "Дії",
      width: 100,
      cellClassName: "actions",
      getActions: ({ id, row }) => {
        const currentRow = row as IUser;
        const isInEditMode = rowModesModel[id]?.mode === GridRowModes.Edit;
        if (isInEditMode) {
          return [
            <GridActionsCellItem
              icon={<Save />}
              label="Save"
              sx={{
                color: "primary.main",
              }}
              onClick={handleSaveClick(id)}
            />,
            <GridActionsCellItem
              icon={<Cancel />}
              label="Cancel"
              className="textPrimary"
              onClick={handleCancelClick(id)}
              color="inherit"
            />,
          ];
        } else if (getAccessByRole(role, currentRow.role)) {
          return [
            <GridActionsCellItem
              icon={<Edit />}
              label="Edit"
              className="textPrimary"
              onClick={handleEditClick(id)}
              color="inherit"
            />,
            <GridActionsCellItem
              icon={<Delete />}
              label="Delete"
              onClick={handleDeleteClick(id)}
              color="inherit"
            />,
          ];
        }
        return [];
      },
    });
  }

  useEffect(() => {
    (async () => {
      setLoading(true);
      const usersResponse = await UserService.getUsers();
      const users = usersResponse.data;

      setUsers(users);

      setLoading(false);
    })();
  }, [id]);

  return (
    <>
      <Title title="Люди" subtitle="Люди с которыми у вас есть связь" />
      <Box
        mt="40px"
        height="75vh"
        sx={{
          "& .MuiDataGrid-root": {
            border: "none",
          },
          "& .MuiDataGrid-cell": {
            borderBottom: "none",
          },
          "& .MuiDataGrid-columnHeaders": {
            backgroundColor: theme.palette.background.paper,
            color: theme.palette.primary[300],
            borderBottom: "none",
          },
          "& .MuiDataGrid-virtualScroller": {
            backgroundColor: theme.palette.background,
          },
          "& .MuiDataGrid-footerContainer": {
            backgroundColor: theme.palette.background.paper,
            color: theme.palette.primary[300],
            borderTop: "none",
          },
          "& .MuiDataGrid-toolbarContainer .MuiButton-text": {
            color: `${theme.palette.primary[300]} !important`,
          },
        }}
      >
        <DataGrid
          localeText={ukUA.components.MuiDataGrid.defaultProps.localeText}
          rows={users || []}
          columns={columns}
          editMode="row"
          rowModesModel={isAdmin ? rowModesModel : undefined}
          onRowModesModelChange={isAdmin ? handleRowModesModelChange : () => {}}
          onRowEditStop={handleRowEditStop}
          processRowUpdate={processRowUpdate}
          pageSizeOptions={[5]}
          isCellEditable={(params: GridCellParams) => {
            const row = params.row as IUser;
            return getAccessByRole(role, row.role);
          }}
        />
      </Box>
    </>
  );
};

export default People;
