import $api from "../http";
import { AxiosResponse } from "axios";
import { IReview } from "../models/IReview";

export default class ReviewService {
  static async getReviews(): Promise<AxiosResponse<IReview[]>> {
    return $api.get<IReview[]>("reviews");
  }
  static async getReviewsById(id: number): Promise<AxiosResponse<IReview>> {
    return $api.get<IReview>("reviews/" + id);
  }
  static async publicReview(review: IReview): Promise<AxiosResponse> {
    return $api.post(`reviews`, review);
  }
  static async deleteReview(id: number): Promise<AxiosResponse> {
    return $api.delete(`reviews?id=${id}`);
  }
}
