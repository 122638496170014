import { useEffect } from "react";
import { useNavigate } from "react-router-dom";

import { Box, Button, Checkbox, FormControlLabel } from "@mui/material";
import { Add } from "@mui/icons-material/";

import { useActions, useTypedSelector } from "../../hooks";
import {
  InputBlocks,
  InputBox,
  InputDate,
  InputFile,
  InputSelect,
  InputSelectMultiple,
} from "../../components";

import { ICategory } from "../../models/ICategory";
import { required } from "../../utils/validators";

const WorksItemLayout = () => {
  const navigate = useNavigate();
  const { mainCategories, childrenByCategory } = useTypedSelector(
    (state) => state.category
  );
  const { work, isTouchedWork } = useTypedSelector((state) => state.work);
  const {
    showModal,
    getChildrenByCategory,
    changeWork,
    uploadWorkCover,
    uploadWorkImages,
    deleteWorkCover,
    deleteWorkImage,
    createWork,
    touchWork,
    getCategories,
  } = useActions();

  const changeSelectedCategory = (event: any) => {
    const { value } = event.target;

    changeWork("types", []);
    getChildrenByCategory(Number(value));
    changeWork("categoryId", value);
  };
  const handleChangeTypes = (value: ICategory[]) => {
    changeWork("types", value);
  };
  const handleChangeWork = (e: any) => {
    const { name, value } = e.target;
    changeWork(name, value);
  };
  const handleChangeIsPreview = (e: any, isPreview: boolean) => {
    const { name } = e.target;
    changeWork(name, isPreview);
  };
  const handleuploadWorkCover = (e: any) => {
    uploadWorkCover(work.id, e);
  };
  const handleuploadWorkImages = (e: any) => {
    uploadWorkImages(work.id, e);
  };
  const handleDeleteWorkCover = (path: any) => {
    showModal("Ви дійсно бажаєте видалити зображення?", () =>
      deleteWorkCover(work.id, path)
    );
  };
  const handleDeleteWorkImage = (imageIndex: number, path: string) => {
    showModal("Ви дійсно бажаєте видалити зображення?", () =>
      deleteWorkImage(work.id, imageIndex, path)
    );
  };
  const handleChangeDate = (date: any) => {
    changeWork("date", date);
  };
  const handleCreateWork = () => {
    if (!isTouchedWork) touchWork();

    let newErrors = [];
    for (const [key, value] of Object.entries(work)) {
      switch (key) {
        case "title":
        case "previewImage":
        case "date":
        case "categoryId":
          if (!value) newErrors.push("");
          continue;
        default:
          continue;
      }
    }

    if (newErrors.length === 0) {
      createWork(work);
      navigate("/works");
    }
  };

  useEffect(() => {}, []);

  return (
    <>
      <Box
        sx={{
          m: "40px auto",
          maxWidth: "400px",
          display: "flex",
          flexDirection: "column",
          "& > *": {
            mb: 4,
          },
        }}
      >
        <InputBox
          label="Заголовок"
          handleChange={(e: any) => handleChangeWork(e)}
          name={"title"}
          value={work.title || ""}
          validate={[required]}
          isTouched={isTouchedWork}
        />

        <InputFile
          name={"previewImage"}
          value={work.previewImage}
          label="Завантажте фото для обкладинки"
          addBlockImage={(formData: any) => handleuploadWorkCover(formData)}
          handleDeleteFile={handleDeleteWorkCover}
          validate={[required]}
          isTouched={isTouchedWork}
        />

        <InputFile
          name={"images"}
          value={work.images}
          label="Завантажте фото для показу"
          multiple={true}
          addBlockImage={(formData: any) => handleuploadWorkImages(formData)}
          handleDeleteFile={handleDeleteWorkImage}
        />

        <InputSelect
          label="Категорія"
          name="categoryId"
          value={String(work.categoryId)}
          list={mainCategories}
          onChange={changeSelectedCategory}
          validate={[required]}
          isTouched={isTouchedWork}
        />

        {childrenByCategory.length > 0 && (
          <InputSelectMultiple
            value={work.types}
            label={"Типи роботи"}
            onChange={handleChangeTypes}
            list={childrenByCategory}
          />
        )}

        <InputDate
          name="date"
          value={work.date}
          onChange={handleChangeDate}
          validate={[required]}
          isTouched={isTouchedWork}
        />

        <InputBlocks blocks={work.blocks} workId={work.id} />

        <InputBox
          label="Час виконання"
          handleChange={(e: any) => handleChangeWork(e)}
          name={"workDuration"}
          value={work.workDuration || ""}
        />
        <InputBox
          label="Вартість (у грн)"
          type="number"
          handleChange={(e: any) => handleChangeWork(e)}
          name={"workCost"}
          value={work.workCost || ""}
        />

        <FormControlLabel
          control={
            <Checkbox
              checked={work.isPreview}
              name="isPreview"
              onChange={handleChangeIsPreview}
            />
          }
          label="Передперегляд"
        />

        <Button
          sx={{ lineHeight: 1 }}
          color="primary"
          variant="contained"
          onClick={() => handleCreateWork()}
        >
          Публікувати
          <Add sx={{ ml: "12px" }} />
        </Button>
      </Box>
    </>
  );
};
export default WorksItemLayout;
