import { useEffect, useState, useMemo } from "react";

import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { uk } from "date-fns/locale";
import { useActions, useTypedSelector } from "../../hooks";
import { Box } from "@mui/material";

const InputDate = ({
  value,
  name,
  onChange,
  validate,
  isTouched = false,
}: {
  value: Date | null;
  name: string;
  onChange: any;
  validate: any[];
  isTouched?: boolean;
}) => {
  const { isAdmin } = useTypedSelector((state) => state.user);
  const [isError, setIsError] = useState<boolean | string>(false);
  const [instinctError, setInstinctError] = useState<string | null>("");

  const editAccess = useMemo(() => {
    if (!isAdmin) {
      return false;
    }
    return true;
  }, [isAdmin]);

  const errorMessage = useMemo(() => {
    switch (isError) {
      case "maxDate":
      case "minDate": {
        return "Будь ласка, введіть коректний рік";
      }
      case "invalidDate": {
        return "Дата некоректна";
      }
      case null:
        return "";
      default: {
        return isError;
      }
    }
  }, [isError]);

  useEffect(() => {
    if (!isTouched) {
      setIsError(false);
      return;
    }
    if (!validate || validate.length <= 0) return;

    let newIsError: boolean | string = false;

    for (let i = 0; i < validate.length; i++) {
      const item = validate[i];
      const errorTextOrUndefined = item(value);
      if (errorTextOrUndefined) {
        newIsError = errorTextOrUndefined;
        break;
      }
    }

    if (instinctError) {
      newIsError = instinctError;
    }

    setIsError(newIsError);
  }, [isTouched, value, instinctError]);

  if (!editAccess) return <div>{String(value)}</div>;

  return (
    <Box sx={{ "&>*": { width: "100%" } }}>
      <LocalizationProvider dateAdapter={AdapterDateFns} adapterLocale={uk}>
        <DatePicker
          format="dd.MM.yyyy"
          label="Дата публікації"
          onChange={(date: Date | null) => onChange(date)}
          selectedSections={undefined}
          onSelectedSectionsChange={undefined}
          maxDate={new Date()}
          value={value}
          onError={(err) => setInstinctError(err)}
          slotProps={{
            textField: {
              error: !!isError,
              color: !!isError ? "error" : "primary",
              helperText: !!isError ? errorMessage : "",
            },
          }}
        />
      </LocalizationProvider>
    </Box>
  );
};
export default InputDate;
