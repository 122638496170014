import styled from "@emotion/styled";
import Box from "@mui/material/Box";

const FlexBetween = styled(Box)({
  display: "flex",
  justifyContent: "space-between",
  alignItems: "center",
});

export default FlexBetween;
