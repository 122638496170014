import { Dispatch } from "redux";

import { IReview } from "../../models/IReview";
import { ReviewsAction, ReviewsActionTypes } from "../../models/redux/reviews";
import { ViewAction, ViewActionTypes } from "../../models/redux/view";

import ReviewService from "../../services/ReviewService";
import { format } from "date-fns";

export const getReviews = () => {
  return async (dispatch: Dispatch<ReviewsAction | ViewAction>) => {
    dispatch({ type: ViewActionTypes.SET_LOADING, payload: true });
    try {
      const response = await ReviewService.getReviews();
      dispatch({
        type: ReviewsActionTypes.SET_REVIEWS,
        payload: response.data,
      });
    } catch (e: any) {
      const alertText = e?.response?.data?.message ?? "Упс, щось пішло не так!";
      dispatch({
        type: ViewActionTypes.SHOW_ALERT,
        payload: { isShowAlert: true, alertText, alertType: "error" },
      });
    }
    dispatch({ type: ViewActionTypes.SET_LOADING, payload: false });
  };
};
export const getReviewById = (id: number) => {
  return async (dispatch: Dispatch<ReviewsAction | ViewAction>) => {
    dispatch({ type: ViewActionTypes.SET_LOADING, payload: true });
    try {
      const response = await ReviewService.getReviewsById(id);
      const review = response.data;

      if (review["date"]) {
        review["date"] = new Date(review.date);
      }

      dispatch({
        type: ReviewsActionTypes.SET_REVIEWS_ITEM,
        payload: review,
      });
    } catch (e: any) {
      const alertText = e?.response?.data?.message ?? "Упс, щось пішло не так!";
      dispatch({
        type: ViewActionTypes.SHOW_ALERT,
        payload: { isShowAlert: true, alertText, alertType: "error" },
      });
    }
    dispatch({ type: ViewActionTypes.SET_LOADING, payload: false });
  };
};
export const changeReview = (name: string, value: any) => {
  return (dispatch: Dispatch<ReviewsAction>) => {
    dispatch({
      type: ReviewsActionTypes.CHANGE_REVIEWS_ITEM,
      payload: { name, value },
    });
  };
};
export const publicReview = (review: IReview) => {
  return async (dispatch: Dispatch<ReviewsAction | ViewAction>) => {
    dispatch({ type: ViewActionTypes.SET_LOADING, payload: true });
    try {
      const { date } = review;

      review["formatedDate"] = format(date ?? new Date(), "yyyy-MM-dd");
      await ReviewService.publicReview(review);

      dispatch({
        type: ReviewsActionTypes.PUBLIC_REVIEWS_ITEM,
      });
    } catch (e: any) {
      const alertText = e?.response?.data?.message ?? "Упс, щось пішло не так!";
      dispatch({
        type: ViewActionTypes.SHOW_ALERT,
        payload: { isShowAlert: true, alertText, alertType: "error" },
      });
    }
    dispatch({ type: ViewActionTypes.SET_LOADING, payload: false });
  };
};
export const touchReview = () => {
  return (dispatch: Dispatch<ReviewsAction>) => {
    dispatch({
      type: ReviewsActionTypes.TOUCH_REVIEWS_ITEM,
    });
  };
};
export const deleteReview = (id: number) => {
  return async (dispatch: Dispatch<ReviewsAction | ViewAction>) => {
    dispatch({ type: ViewActionTypes.SET_LOADING, payload: true });
    try {
      await ReviewService.deleteReview(id);
      dispatch({
        type: ReviewsActionTypes.DELETE_REVIEWS_ITEM,
        payload: id,
      });
    } catch (e: any) {
      const alertText = e?.response?.data?.message ?? "Упс, щось пішло не так!";
      dispatch({
        type: ViewActionTypes.SHOW_ALERT,
        payload: { isShowAlert: true, alertText, alertType: "error" },
      });
    }
    dispatch({ type: ViewActionTypes.SET_LOADING, payload: false });
  };
};
