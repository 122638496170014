import { Fragment, useEffect, useMemo, useState } from "react";
import {
  Box,
  Drawer,
  IconButton,
  List,
  ListItem,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  Typography,
  useTheme,
} from "@mui/material";

import { ChevronLeft, ChevronRightOutlined } from "@mui/icons-material";
import { useLocation, useNavigate } from "react-router-dom";
import { FlexBetween } from "../";
import { isProtected, useTypedSelector } from "../../hooks";
import { LinksList } from "../../routes";

const CustomDrawer = ({
  drawerWidth,
  isSidebarOpen,
  setIsSidebarOpen,
  isNonMobile,
}: any) => {
  const { id, isAdmin, role } = useTypedSelector((state) => state.user);
  const isAuth = useMemo(() => !!id, [id]);
  const { pathname } = useLocation();
  const [active, setActive] = useState("");
  const navigate = useNavigate();

  useEffect(() => {
    setActive(pathname.substring(1));
    if (!isNonMobile) setIsSidebarOpen(false);
  }, [pathname, isNonMobile, setIsSidebarOpen]);

  if (!isAuth) return <></>;

  return (
    <Drawer
      open={isSidebarOpen}
      onClose={() => setIsSidebarOpen(false)}
      variant="persistent"
      transitionDuration={300}
      anchor="left"
      sx={{
        flexShrink: 0,
        opacity: isSidebarOpen ? 1 : 0,
        visibility: isSidebarOpen ? "visible" : "hidden",
        transition: "all 300ms cubic-bezier(0, 0, 0.2, 1)",
        width: isSidebarOpen ? drawerWidth : 0,
        "& .MuiDrawer-paper": {
          backgroundColor: "background.paper",
          boxSizing: "border-box",
          borderWidth: isNonMobile ? 0 : "2px",
          width: isSidebarOpen ? drawerWidth : 0,
          transition: "all 300ms cubic-bezier(0, 0, 0.2, 1)",
        },
      }}
    >
      <Box m="1.5rem .5rem 2rem 2rem">
        <FlexBetween>
          <Box display="flex" alignItems="center" gap="0.5rem">
            <Typography variant="h4" color="primary" fontWeight="bold">
              Admin Panel
            </Typography>
          </Box>
          {!isNonMobile && (
            <IconButton onClick={() => setIsSidebarOpen(!isSidebarOpen)}>
              <ChevronLeft />
            </IconButton>
          )}
        </FlexBetween>
      </Box>
      <List>
        {LinksList.map((link, index) => {
          const { text, icon, path } = link;

          if (!icon) {
            return (
              <Typography key={text} sx={{ m: "2.25rem 0 1rem 3rem" }}>
                {text}
              </Typography>
            );
          }

          const isAllowed = isProtected(link, isAdmin, role);

          if (!isAllowed) {
            return <Fragment key={index} />;
          }

          const isActive = active === path;
          return (
            <ListItem key={text} disablePadding>
              <ListItemButton
                onClick={() => {
                  navigate(`/${path}`);
                  setActive(path);
                }}
                sx={{
                  backgroundColor: isActive ? "primary.300" : "transparent",
                  color: isActive ? "primary.contrastText" : "primary.300",
                }}
              >
                <ListItemIcon
                  sx={{
                    ml: "2rem",
                    color: isActive ? "primary.contrastText" : "primary.300",
                  }}
                >
                  {icon}
                </ListItemIcon>
                <ListItemText
                  sx={{
                    "> *": {
                      fontSize: "0.9rem",
                    },
                  }}
                  primary={text}
                />
                {isActive && <ChevronRightOutlined sx={{ ml: "auto" }} />}
              </ListItemButton>
            </ListItem>
          );
        })}
      </List>
    </Drawer>
  );
};

export default CustomDrawer;
