import $api from "../http";
import { AxiosResponse } from "axios";
import { IWork, IWorkBlockTypes, IWorkPreview } from "../models/IWork";

export default class WorkService {
  static async getWorksPreview(): Promise<AxiosResponse<IWorkPreview[]>> {
    return $api.get<IWorkPreview[]>("works/preview");
  }

  static async getWorkDraft(): Promise<AxiosResponse<IWork>> {
    return $api.get<IWork>("works/draft");
  }
  static async createWorkDraft(): Promise<AxiosResponse> {
    return $api.post("works/draft");
  }
  static async getWorkById(id: string): Promise<AxiosResponse<IWork>> {
    return $api.get<IWork>("works/item?id=" + id);
  }

  static async addCover(workId: number, cover: string): Promise<AxiosResponse> {
    return $api.post("works/cover", { workId, cover });
  }
  static async addImagesPaths(
    workId: number,
    images: string[]
  ): Promise<AxiosResponse> {
    return $api.post("works/images", { workId, images });
  }
  static async deleteWorkCover(
    workId: number,
    path: string
  ): Promise<AxiosResponse> {
    return $api.delete(`works/cover?workid=${workId}&path=${path}`);
  }
  static async deleteWorkImage(
    workId: number,
    path: string
  ): Promise<AxiosResponse> {
    return $api.delete(`works/image?workid=${workId}&path=${path}`);
  }
  static async createBlock(
    workId: number,
    type: IWorkBlockTypes
  ): Promise<AxiosResponse> {
    return $api.post("works/block", { workId, type });
  }
  static async deleteBlock(
    workId: number,
    blockIndex: number
  ): Promise<AxiosResponse> {
    return $api.delete(`works/block?workid=${workId}&blockindex=${blockIndex}`);
  }
  static async updateBlocksPosition(
    workId: number,
    blockIndex: number,
    direction: 1 | -1
  ): Promise<AxiosResponse> {
    return $api.post("works/blockposition", {
      workId,
      blockIndex,
      direction,
    });
  }

  static async createBlogBlockListItem(
    workId: number,
    blockIndex: number
  ): Promise<AxiosResponse> {
    return $api.post("works/blocklistitem", { workId, blockIndex });
  }
  static async deleteBlogBlockListItem(
    workId: number,
    blockIndex: number,
    listItemIndex: number
  ): Promise<AxiosResponse> {
    return $api.delete(
      `works/blocklistitem?workid=${workId}&blockindex=${blockIndex}&listitemindex=${listItemIndex}`
    );
  }

  static async createWork(work: IWork): Promise<AxiosResponse> {
    return $api.post(`works`, work);
  }
  static async deleteWork(id: number): Promise<AxiosResponse> {
    return $api.delete(`works?id=` + id);
  }
}
