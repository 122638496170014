import { userReducer } from "./userReducer";
import { viewReducer } from "./viewReducer";
import { workReducer } from "./workReducer";
import { categoryReducer } from "./categoryReducer";
import { reviewReducer } from "./reviewReducer";
import { combineReducers } from "redux";

export const rootReducer = combineReducers({
  user: userReducer,
  view: viewReducer,
  work: workReducer,
  category: categoryReducer,
  review: reviewReducer,
});

export type RootState = ReturnType<typeof rootReducer>;
