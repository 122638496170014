import { IReview } from "../../models/IReview";
import {
  ReviewsAction,
  ReviewsActionTypes,
  ReviewsState,
} from "../../models/redux/reviews";

const initialState: ReviewsState = {
  reviews: [],
  review: {
    review: "",
    request: "",
    phone: "",
    address: "",
    authorName: "",
    date: null,
    categoryId: 1,
  } as IReview,
  isTouchedReview: false,
};

export const reviewReducer = (
  state = initialState,
  action: ReviewsAction
): ReviewsState => {
  switch (action.type) {
    case ReviewsActionTypes.SET_REVIEWS: {
      return { ...state, reviews: action.payload };
    }
    case ReviewsActionTypes.SET_REVIEWS_ITEM: {
      return {
        ...state,
        isTouchedReview: initialState.isTouchedReview,
        review: action.payload,
      };
    }
    case ReviewsActionTypes.CHANGE_REVIEWS_ITEM:
      return {
        ...state,
        review: {
          ...state.review,
          [action.payload.name]: action.payload.value,
        },
      };

    case ReviewsActionTypes.PUBLIC_REVIEWS_ITEM:
      return {
        ...state,
        review: initialState.review,
        isTouchedReview: initialState.isTouchedReview,
      };
    case ReviewsActionTypes.TOUCH_REVIEWS_ITEM:
      return { ...state, isTouchedReview: true };
    case ReviewsActionTypes.DELETE_REVIEWS_ITEM:
      return {
        ...state,
        reviews: state.reviews.filter(
          (review) => Number(review.id) !== Number(action.payload)
        ),
      };
    default:
      return state;
  }
};
