import { useState, useEffect } from "react";
import { Outlet } from "react-router-dom";

import {
  Box,
  useMediaQuery,
  Alert,
  Snackbar,
  Backdrop,
  CircularProgress,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  Button,
  DialogActions,
} from "@mui/material";
import { Header, Drawer } from "../../components";
import { useTypedSelector, useActions } from "../../hooks";

const Layout = () => {
  const drawerWidth = "300px";
  const isNonMobile = useMediaQuery("(min-width: 600px)");
  const {
    isShowAlert,
    alertText,
    alertType,
    isLoading,
    isShowModal,
    modalText,
    modalCallback,
  } = useTypedSelector((state) => state.view);
  const { closeAlert, closeModal } = useActions();
  const [isSidebarOpen, setIsSidebarOpen] = useState(false);

  useEffect(() => {
    setIsSidebarOpen(isNonMobile);
  }, [isNonMobile]);

  return (
    <Box display={isNonMobile ? "flex" : "block"} width="100%" height="100%">
      <Drawer
        isNonMobile={isNonMobile}
        drawerWidth={drawerWidth}
        isSidebarOpen={isSidebarOpen}
        setIsSidebarOpen={setIsSidebarOpen}
      />
      <Box
        flexGrow={1}
        sx={{
          transition: "all 300ms cubic-bezier(0, 0, 0.2, 1)",
          width: isSidebarOpen ? `calc(100% - ${drawerWidth})` : "100%",
        }}
      >
        <Header
          isSidebarOpen={isSidebarOpen}
          setIsSidebarOpen={setIsSidebarOpen}
        />

        <Box m="1.5rem 2.5rem">
          <Outlet />
        </Box>
      </Box>

      <Snackbar
        anchorOrigin={{ vertical: "top", horizontal: "center" }}
        open={isShowAlert}
        autoHideDuration={4000}
        onClose={closeAlert}
      >
        <Alert
          severity={alertType}
          variant="filled"
          sx={{ width: "100%" }}
          onClose={closeAlert}
        >
          {alertText}
        </Alert>
      </Snackbar>

      <Backdrop
        sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={isLoading}
      >
        <CircularProgress color="inherit" />
      </Backdrop>

      <Dialog
        open={isShowModal}
        onClose={closeModal}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">Підтвердіть дію</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            {modalText}
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={closeModal} color="error">
            Ні
          </Button>
          <Button onClick={modalCallback} autoFocus color="success">
            Так
          </Button>
        </DialogActions>
      </Dialog>
    </Box>
  );
};

export default Layout;
