import { ICategory } from "../ICategory";

export interface CategoriesState {
  categories: ICategory[];
  mainCategories: ICategory[];
  childrenByCategory: ICategory[];
}

export enum CategoriesActionTypes {
  SET_CATEGORIES = "SET_CATEGORIES",
  SET_MAIN_CATEGORIES = "SET_MAIN_CATEGORIES",
  SET_CHILDREN_BY_CATEGORY = "SET_CHILDREN_BY_CATEGORY",
}

interface SetCategoriesAction {
  type: CategoriesActionTypes.SET_CATEGORIES;
  payload: ICategory[];
}
interface SetMainCategoriesAction {
  type: CategoriesActionTypes.SET_MAIN_CATEGORIES;
  payload: ICategory[];
}
interface SetChildrenByCategoryAction {
  type: CategoriesActionTypes.SET_CHILDREN_BY_CATEGORY;
  payload: number;
}

export type CategoriesAction =
  | SetCategoriesAction
  | SetMainCategoriesAction
  | SetChildrenByCategoryAction;
