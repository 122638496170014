import { Typography, Box } from "@mui/material";

const Title = ({ title, subtitle }) => {
  return (
    <Box>
      <Typography
        variant="h2"
        color={"primary"}
        fontWeight="bold"
        sx={{ mb: "5px" }}
      >
        {title}
      </Typography>
      <Typography variant="h5" color="primary.100">
        {subtitle}
      </Typography>
    </Box>
  );
};

export default Title;
