import { roleScore } from "../constants";
import { IRoles } from "../models/IUser"

const getAccessRolesByRole = (role: IRoles): IRoles[] => {
    
    const roles: IRoles[] = [];
    const currentRoleScore = roleScore[role];
    for (const [key, value] of Object.entries(roleScore)) {
        if(currentRoleScore > value) roles.push(key as IRoles)
    }
    return roles;
}
export default getAccessRolesByRole;
 