import { useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";

import { Box, Button } from "@mui/material";
import { Add } from "@mui/icons-material/";

import { useActions, useTypedSelector } from "../../hooks";
import { InputBox, InputDate, InputSelect } from "../../components";

import { required } from "../../utils/validators";

const ReviewsSingle = () => {
  const navigate = useNavigate();
  const { id } = useParams();

  const { mainCategories } = useTypedSelector((state) => state.category);
  const { review, isTouchedReview } = useTypedSelector((state) => state.review);
  const {
    changeReview,
    getReviewById,
    publicReview,
    touchReview,
    getMainCategories,
  } = useActions();

  const changeSelectedCategory = (event: any) => {
    const { value } = event.target;
    changeReview("categoryId", value);
  };
  const handleChangeReview = (e: any) => {
    const { name, value } = e.target;
    changeReview(name, value);
  };
  const handleChangeDate = (date: any) => {
    changeReview("date", date);
  };
  const handleCreateWork = () => {
    if (!isTouchedReview) touchReview();

    let newErrors = [];
    for (const [key, value] of Object.entries(review)) {
      switch (key) {
        case "review":
        case "authorName":
        case "date":
        case "categoryId":
          if (!value) newErrors.push("");
          continue;
        default:
          continue;
      }
    }

    if (newErrors.length === 0) {
      publicReview(review);
      navigate("/reviews");
    }
  };

  useEffect(() => {
    if (id) getReviewById(Number(id));
    getMainCategories();
  }, []);

  return (
    <>
      <Box
        sx={{
          m: "40px auto",
          maxWidth: "400px",
          display: "flex",
          flexDirection: "column",
          "& > *": {
            mb: 4,
          },
        }}
      >
        <InputBox
          label="Відгук"
          textarea={true}
          handleChange={(e: any) => handleChangeReview(e)}
          name={"review"}
          value={review.review || ""}
          validate={[required]}
          isTouched={isTouchedReview}
        />

        <InputBox
          label="Відповідь"
          textarea={true}
          handleChange={(e: any) => handleChangeReview(e)}
          name={"request"}
          value={review.request || ""}
        />

        <InputBox
          label="Телефон"
          handleChange={(e: any) => handleChangeReview(e)}
          name={"phone"}
          value={review.phone || ""}
          disabled={true}
        />

        <InputBox
          label="Адрес"
          textarea={true}
          handleChange={(e: any) => handleChangeReview(e)}
          name={"address"}
          value={review.address || ""}
          disabled={true}
        />

        <InputBox
          label="Автор"
          handleChange={(e: any) => handleChangeReview(e)}
          name={"authorName"}
          value={review.authorName || ""}
          validate={[required]}
          isTouched={isTouchedReview}
        />

        <InputDate
          name="date"
          value={review.date}
          onChange={handleChangeDate}
          validate={[required]}
          isTouched={isTouchedReview}
        />

        <InputSelect
          label="Категорія"
          name="categoryId"
          value={String(review.categoryId)}
          onChange={changeSelectedCategory}
          list={mainCategories}
          validate={[required]}
          isTouched={isTouchedReview}
        />

        <Button
          sx={{ lineHeight: 1 }}
          color="primary"
          variant="contained"
          onClick={() => handleCreateWork()}
        >
          Публікувати
          <Add sx={{ ml: "12px" }} />
        </Button>
      </Box>
    </>
  );
};

export default ReviewsSingle;
