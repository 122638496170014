import $api from "../http";
import { AxiosResponse } from "axios";

export default class FilesService {
  static async uploadImages(formData: any): Promise<AxiosResponse<string[]>> {
    return $api.post<string[]>("files/postupload/", formData);
  }

  static async deleteImage(url: string): Promise<AxiosResponse> {
    return $api.delete("files/" + url);
  }
}
