import { IWorkPreview, IWork, IWorkBlockTypes, IWorkError } from "../IWork";

export interface WorksState {
  worksPreview: IWorkPreview[];
  work: IWork;
  isTouchedWork: boolean;
}

export enum WorksActionTypes {
  SET_WORKS_PREVIEW = "SET_WORKS_PREVIEW",

  SET_WORK = "SET_WORK",
  CHANGE_WORK = "CHANGE_WORK",
  UPLOAD_WORK_COVER = "UPLOAD_WORK_COVER",
  UPLOAD_WORK_IMAGES = "UPLOAD_WORK_IMAGES",
  DELETE_WORK_COVER = "DELETE_WORK_COVER",
  DELETE_WORK_IMAGE = "DELETE_WORK_IMAGE",

  CREATE_WORK_BLOCK = "CREATE_WORK_BLOCK",
  DELETE_WORK_BLOCK = "DELETE_WORK_BLOCK",
  CHANGE_WORK_BLOCK = "CHANGE_WORK_BLOCK",
  CHANGE_WORK_BLOCK_POSITION = "CHANGE_WORK_BLOCK_POSITION",

  CREATE_WORK_BLOCK_LIST_ITEM = "CREATE_WORK_BLOCK_LIST_ITEM",
  DELETE_WORK_BLOCK_LIST_ITEM = "DELETE_WORK_BLOCK_LIST_ITEM",
  CHANGE_WORK_BLOCK_LIST_ITEM = "CHANGE_WORK_BLOCK_LIST_ITEM",

  CREATE_WORK = "CREATE_WORK",
  TOUCH_WORK = "TOUCH_WORK",
  DELETE_WORK = "DELETE_WORK",
}

interface SetWorksPreviewAction {
  type: WorksActionTypes.SET_WORKS_PREVIEW;
  payload: IWorkPreview[];
}

interface SetWorkAction {
  type: WorksActionTypes.SET_WORK;
  payload: IWork;
}
interface ChangeWorkAction {
  type: WorksActionTypes.CHANGE_WORK;
  payload: { name: string; value: any };
}
interface UploadWorkCoverAction {
  type: WorksActionTypes.UPLOAD_WORK_COVER;
  payload: string;
}
interface UploadWorkImagesAction {
  type: WorksActionTypes.UPLOAD_WORK_IMAGES;
  payload: string[];
}
interface DeleteWorkCoverAction {
  type: WorksActionTypes.DELETE_WORK_COVER;
}
interface DeleteWorkImageAction {
  type: WorksActionTypes.DELETE_WORK_IMAGE;
  payload: number;
}

interface CreateWorkBlockAction {
  type: WorksActionTypes.CREATE_WORK_BLOCK;
  payload: IWorkBlockTypes;
}
interface DeleteWorkBlockAction {
  type: WorksActionTypes.DELETE_WORK_BLOCK;
  payload: number;
}
interface ChangeWorkBlockAction {
  type: WorksActionTypes.CHANGE_WORK_BLOCK;
  payload: {
    blockIndex: number;
    value: string;
  };
}
interface ChangeWorkBlockPositionAction {
  type: WorksActionTypes.CHANGE_WORK_BLOCK_POSITION;
  payload: {
    blockIndex: number;
    direction: 1 | -1;
  };
}

interface CreateWorkBlockListItemAction {
  type: WorksActionTypes.CREATE_WORK_BLOCK_LIST_ITEM;
  payload: number;
}
interface DeleteWorkBlockListItemAction {
  type: WorksActionTypes.DELETE_WORK_BLOCK_LIST_ITEM;
  payload: {
    blockIndex: number;
    listItemIndex: number;
  };
}
interface ChangeWorkBlockListItemAction {
  type: WorksActionTypes.CHANGE_WORK_BLOCK_LIST_ITEM;
  payload: {
    blockIndex: number;
    listItemIndex: number;
    value: string;
  };
}

interface CreateWorkAction {
  type: WorksActionTypes.CREATE_WORK;
}
interface TouchWorkAction {
  type: WorksActionTypes.TOUCH_WORK;
}
interface DeleteWorkAction {
  type: WorksActionTypes.DELETE_WORK;
  payload: number;
}

export type WorksAction =
  | SetWorksPreviewAction
  //
  | SetWorkAction
  | ChangeWorkAction
  | UploadWorkCoverAction
  | UploadWorkImagesAction
  | DeleteWorkCoverAction
  | DeleteWorkImageAction
  //
  | CreateWorkBlockAction
  | DeleteWorkBlockAction
  | ChangeWorkBlockAction
  | ChangeWorkBlockPositionAction
  //
  | CreateWorkBlockListItemAction
  | DeleteWorkBlockListItemAction
  | ChangeWorkBlockListItemAction
  //
  | CreateWorkAction
  | TouchWorkAction
  | DeleteWorkAction;
