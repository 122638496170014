import { INotification, IUser, IRoles } from '../IUser';

export interface UserState{
   id: null | string;
   isActivated: boolean;
   name: string;
   login: string;
   role: IRoles;
   isAdmin: boolean;
   notifications: INotification[];
}

export enum UserActionTypes {
   USER_REGISTER = 'USER_REGISTER',
   USER_LOGIN = 'USER_LOGIN',
   USER_LOGOUT = "USER_LOGOUT",
   USER_INIT = "USER_INIT",
}

interface RegisterUserAction {
   type: UserActionTypes.USER_REGISTER;
   payload: IUser
}

interface LoginUserAction {
   type: UserActionTypes.USER_LOGIN;
   payload: IUser
}

interface LogoutUserAction {
   type: UserActionTypes.USER_LOGOUT;
}

interface InitUserAction {
   type: UserActionTypes.USER_INIT;
   payload: IUser
}

export type UserAction =
   LoginUserAction |
   RegisterUserAction |
   LogoutUserAction |
   InitUserAction 