import { IRoles } from '../../models/IUser';
import { UserAction, UserActionTypes, UserState } from '../../models/redux/user';

const initialState: UserState = {
   id: null,
   isActivated: false,
   name: '',
   login: '',
   role: IRoles.MANAGER,
   isAdmin: false,
   notifications: []
}

export const userReducer = (state = initialState, action: UserAction): UserState => {
   switch (action.type) {
      
      case UserActionTypes.USER_LOGIN:
         return { ...state, ...action.payload }

      case UserActionTypes.USER_REGISTER:
         return { ...state, ...action.payload }
  
      case UserActionTypes.USER_LOGOUT:
         return { ...initialState }

      case UserActionTypes.USER_INIT:
         return { ...state, ...action.payload }

      default:
         return state
   }
}
