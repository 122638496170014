import { useState, useEffect, useMemo } from "react";

import { Box, FormControl, InputLabel, MenuItem, Select } from "@mui/material";
import { useActions, useTypedSelector } from "../../hooks";

const InputSelect = ({
  value,
  label = "",
  list,
  name,
  onChange,
  validate,
  isTouched = false,
}: {
  value: string;
  label?: string;
  list: any[];
  name: string;
  onChange: any;
  validate: any[];
  isTouched?: boolean;
}) => {
  const { isAdmin } = useTypedSelector((state) => state.user);
  const [isError, setIsError] = useState(false);

  const editAccess = useMemo(() => {
    if (!isAdmin) {
      return false;
    }
    return true;
  }, [isAdmin]);

  useEffect(() => {
    if (!isTouched) {
      setIsError(false);
      return;
    }
    if (!validate || validate.length <= 0) return;

    let newIsError = false;

    for (let i = 0; i < validate.length; i++) {
      const item = validate[i];
      const errorTextOrUndefined = item(value);
      if (errorTextOrUndefined) {
        newIsError = errorTextOrUndefined;
        break;
      }
    }
    setIsError(newIsError);
  }, [isTouched, value]);

  if (!editAccess) return <div>value</div>;

  return (
    <Box>
      <FormControl fullWidth>
        <InputLabel id={label + name}>{label}</InputLabel>
        <Select
          sx={{ minWidth: "200px", width: "100%" }}
          value={value}
          labelId={label + name}
          label={label}
          onChange={onChange}
          name="categoryId"
          error={!!isError}
        >
          {list.length > 0 &&
            list.map((category) => (
              <MenuItem key={category.id} value={category.id}>
                {category.title}
              </MenuItem>
            ))}
        </Select>
      </FormControl>

      {!!isError && (
        <Box
          sx={{
            color: "error.main",
            fontSize: "0.6428571428571428rem",
            lineHeight: "1.66",
            textAlign: "left",
            marginTop: "3px",
            marginRight: "14px",
            marginBottom: "0",
            marginLeft: "14px",
          }}
        >
          {isError}
        </Box>
      )}
    </Box>
  );
};

export default InputSelect;
