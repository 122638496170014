import { Dispatch } from "redux";
import {
  CategoriesAction,
  CategoriesActionTypes,
} from "../../models/redux/category";
import { ViewAction, ViewActionTypes } from "../../models/redux/view";
import CategoryService from "../../services/CategoryService";

export const getCategories = () => {
  return async (dispatch: Dispatch<CategoriesAction | ViewAction>) => {
    dispatch({ type: ViewActionTypes.SET_LOADING, payload: true });
    try {
      const response = await CategoryService.getCategories();
      dispatch({
        type: CategoriesActionTypes.SET_CATEGORIES,
        payload: response.data,
      });
    } catch (e: any) {
      const alertText = e?.response?.data?.message ?? "Упс, щось пішло не так!";
      dispatch({
        type: ViewActionTypes.SHOW_ALERT,
        payload: { isShowAlert: true, alertText, alertType: "error" },
      });
    }
    dispatch({ type: ViewActionTypes.SET_LOADING, payload: false });
  };
};
export const getMainCategories = () => {
  return async (dispatch: Dispatch<CategoriesAction | ViewAction>) => {
    dispatch({ type: ViewActionTypes.SET_LOADING, payload: true });
    try {
      const response = await CategoryService.getMainCategories();
      dispatch({
        type: CategoriesActionTypes.SET_MAIN_CATEGORIES,
        payload: response.data,
      });
    } catch (e: any) {
      const alertText = e?.response?.data?.message ?? "Упс, щось пішло не так!";
      dispatch({
        type: ViewActionTypes.SHOW_ALERT,
        payload: { isShowAlert: true, alertText, alertType: "error" },
      });
    }
    dispatch({ type: ViewActionTypes.SET_LOADING, payload: false });
  };
};
export const getChildrenByCategory = (categoryId: number) => {
  return (dispatch: Dispatch<CategoriesAction>) => {
    dispatch({
      type: CategoriesActionTypes.SET_CHILDREN_BY_CATEGORY,
      payload: categoryId,
    });
  };
};
