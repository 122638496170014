import { useState, createContext, useMemo, useEffect } from "react";

import { CircularProgress, CssBaseline } from "@mui/material";
import { createTheme, ThemeProvider } from "@mui/material/styles";

import { BrowserRouter as Router } from "react-router-dom";
import useRoutes from "./routes";
import { localStorageToken } from "./constants";
import { useTypedSelector, useActions } from "./hooks/";
import { themeSettings } from "./theme";

export const ColorModeContext = createContext({ toggleColorMode: () => {} });

export default function ToggleColorMode() {
  const { chechAuth } = useActions();
  const { isInitLoading } = useTypedSelector((state) => state.view);
  const routes = useRoutes();

  const [mode, setMode] = useState<"light" | "dark">("dark");

  const colorMode = useMemo(
    () => ({
      toggleColorMode: () => {
        setMode((prevMode) => (prevMode === "light" ? "dark" : "light"));
      },
    }),
    []
  );

  const theme = useMemo(() => createTheme(themeSettings(mode)), [mode]);

  useEffect(() => {
    const token = localStorage.getItem(localStorageToken);
    chechAuth(token);
  }, []);

  return (
    <Router>
      <ColorModeContext.Provider value={colorMode}>
        <ThemeProvider theme={theme}>
          {!isInitLoading ? (
            <>
              <CssBaseline />
              {routes}
            </>
          ) : (
            <div
              style={{
                width: "100vw",
                height: "100vh",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              <CircularProgress color="primary" />
            </div>
          )}
        </ThemeProvider>
      </ColorModeContext.Provider>
    </Router>
  );
}
