import { log } from "console";
import { useEffect } from "react";
import { useParams, Navigate, useNavigate } from "react-router-dom";

import { Title, WorksItemLayout } from "../../components";
import { useActions, useTypedSelector } from "../../hooks";

const WorksSingle = () => {
  const { id } = useParams();
  const navigate = useNavigate();
  const { work } = useTypedSelector((state) => state.work);
  const { isInitLoading } = useTypedSelector((state) => state.view);
  const { setWorkById, getCategories } = useActions();

  useEffect(() => {
    if (id) {
      getCategories();
      setWorkById(id);
    }
  }, [id]);

  if (isInitLoading) return <></>;

  return (
    <>
      <Title title={"Редагування елемента"} subtitle="" />
      {work.id !== 0 && <WorksItemLayout />}
    </>
  );
};
export default WorksSingle;
