import { useState, useMemo } from "react";
import {
  Box,
  FormControl,
  IconButton,
  Typography,
  MenuItem,
  Select,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Button,
  List,
  ListItem,
} from "@mui/material";

import { IWorkBlock, IWorkBlockTypes } from "../../models/IWork";
import { WORKS_BLOCK_TYPES } from "../../constants";

import { InputBox } from "../";

import {
  Add,
  HighlightOff,
  ArrowUpward,
  ArrowDownward,
} from "@mui/icons-material/";
import { useActions, useTypedSelector } from "../../hooks";

export default function InputBlocks({
  workId,
  blocks,
}: {
  workId: number | undefined;
  blocks: IWorkBlock[];
}) {
  const [open, setOpen] = useState(false);
  const [newType, setNewType] = useState<IWorkBlockTypes>(IWorkBlockTypes.P);

  const {
    showModal,
    createWorkBlock,
    deleteWorkBlock,
    changeWorkBlock,
    changeWorkBlocksPosition,
    createWorkBlockListItem,
    deleteWorkBlockListItem,
    changeWorkBlockListItem,
  } = useActions();
  const { isAdmin } = useTypedSelector((state) => state.user);

  const editAccess = useMemo(() => {
    if (!isAdmin) {
    }
    return true;
  }, [isAdmin]);

  const handleClickOpen = () => {
    setOpen(true);
  };
  const handleClose = () => {
    setOpen(false);
  };
  const changeType = (event: any) => {
    setNewType(event.target.value);
  };

  const handleBlockCreate = () => {
    createWorkBlock(workId, newType);
  };
  const handleBlockDelete = (blockIndex: number) => {
    showModal("Ви дійсно бажаєте видалити блок?", () =>
      deleteWorkBlock(workId, blockIndex)
    );
  };
  const handleChangeBlock = (
    e: React.ChangeEvent<HTMLInputElement>,
    blockIndex: number
  ) => {
    changeWorkBlock(e, blockIndex);
  };

  const handleCreateBlockListItem = (blockIndex: number) => {
    createWorkBlockListItem(workId, blockIndex);
  };
  const handleDeleteBlockListItem = (
    blockIndex: number,
    listItemIndex: number
  ) => {
    showModal("Ви дійсно бажаєте видалити цей елемент?", () =>
      deleteWorkBlockListItem(workId, blockIndex, listItemIndex)
    );
  };
  const handleChangeBlockListItem = (
    e: React.ChangeEvent<HTMLInputElement>,
    blockIndex: number,
    listItemIndex: number
  ) => {
    changeWorkBlockListItem(blockIndex, listItemIndex, e.target.value);
  };

  const handleMoveBlock = (blockIndex: number, direction: 1 | -1) => {
    changeWorkBlocksPosition(workId, blockIndex, direction);
  };

  const getFormByType = (
    block: IWorkBlock,
    blockIndex: number,
    blocksArrLength: number
  ) => {
    let jsx = null;
    switch (block.type) {
      case IWorkBlockTypes.P:
        jsx = (
          <InputBox
            handleChange={(e: any) => handleChangeBlock(e, blockIndex)}
            name="text"
            value={block.text || ""}
          />
        );
        break;
      case IWorkBlockTypes.UL:
        jsx = (
          <List>
            {block.list &&
              block.list.length > 0 &&
              block.list.map((item, listItemIndex) => (
                <ListItem key={listItemIndex} sx={{ p: 0, mb: 1 }}>
                  <InputBox
                    key={listItemIndex}
                    handleChange={(e: any) =>
                      handleChangeBlockListItem(e, blockIndex, listItemIndex)
                    }
                    endIcon={true}
                    onDelete={() =>
                      handleDeleteBlockListItem(blockIndex, listItemIndex)
                    }
                    value={item}
                    name={"title"}
                  />
                </ListItem>
              ))}
            <ListItem sx={{ p: 0, mt: "24px", justifyContent: "center" }}>
              <Button
                sx={{ lineHeight: 1 }}
                color="primary"
                onClick={() => handleCreateBlockListItem(blockIndex)}
              >
                Додати до списку
                <Add sx={{ ml: "12px" }} />
              </Button>
            </ListItem>
          </List>
        );
        break;
      default:
        break;
    }

    return (
      <Box sx={{ mt: "48px", width: "100%" }} key={blockIndex}>
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
          }}
        >
          <Typography variant="h3" textAlign="center">
            {block.type}
          </Typography>

          <Box
            sx={{
              display: "flex",
              width: "100%",
              alignItems: "center",
              justifyContent: "flex-end",
            }}
          >
            {blockIndex !== 0 && (
              <IconButton
                onClick={() => handleMoveBlock(blockIndex, -1)}
                sx={{ ml: "8px" }}
                size="medium"
                color="primary"
              >
                <ArrowUpward fontSize="large" />
              </IconButton>
            )}
            {blockIndex !== blocksArrLength - 1 && (
              <IconButton
                onClick={() => handleMoveBlock(blockIndex, 1)}
                sx={{ ml: "8px" }}
                size="medium"
                color="primary"
              >
                <ArrowDownward fontSize="large" />
              </IconButton>
            )}

            <IconButton
              onClick={() => handleBlockDelete(blockIndex)}
              sx={{ ml: "8px" }}
              size="medium"
              color="error"
            >
              <HighlightOff fontSize="large" />
            </IconButton>
          </Box>
        </Box>

        {jsx}
      </Box>
    );
  };

  if (!editAccess) {
    return (
      <div>
        {blocks.length > 0 &&
          blocks.map((block, index) => (
            <li className="travelcompany-input" key={index}>
              <span className="input-label">{block.type}</span>
            </li>
          ))}
      </div>
    );
  }

  return (
    <Box sx={{ width: "100%" }}>
      <FormControl sx={{ width: "100%", alignItems: "center", mt: "12px" }}>
        <Typography variant="h2">Блоки</Typography>
        {blocks &&
          blocks.length > 0 &&
          blocks.map((block, index, blocksArr) =>
            getFormByType(block, index, blocksArr.length)
          )}

        <IconButton
          color="primary"
          sx={{ mt: "12px" }}
          onClick={handleClickOpen}
        >
          <Add />
        </IconButton>
      </FormControl>

      <Dialog open={open} onClose={handleClose}>
        <DialogTitle>Створення Блоку</DialogTitle>
        <DialogContent>
          <Select
            labelId="demo-simple-select-label"
            id="demo-simple-select"
            value={newType}
            onChange={changeType}
            sx={{ minWidth: "200px" }}
          >
            {WORKS_BLOCK_TYPES.map((type) => (
              <MenuItem key={type} value={type}>
                {type}
              </MenuItem>
            ))}
          </Select>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} color="primary" variant="text">
            Cancel
          </Button>
          <Button
            onClick={() => handleBlockCreate()}
            color="primary"
            variant="contained"
          >
            Ok
          </Button>
        </DialogActions>
      </Dialog>
    </Box>
  );
}
