import * as UserActionCreators from "./user";
import * as ViewActionCreators from "./view";
import * as WorkActionCreators from "./work";
import * as CategoryActionCreators from "./category";
import * as ReviewActionCreators from "./review";

const actionCreators = {
  ...UserActionCreators,
  ...ViewActionCreators,
  ...WorkActionCreators,
  ...CategoryActionCreators,
  ...ReviewActionCreators,
};

export default actionCreators;
