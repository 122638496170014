export interface INotification {
  isChecked: boolean;
  text: string;
}

export enum IRoles {
  MANAGER = "MANAGER",
  BLOGGER = "BLOGGER",
  HELPER = "HELPER",
  ADMIN = "ADMIN",
  OWNER = "OWNER",
  GOD = "GOD",
}

export interface IUser {
  login: string;
  id: string;
  name: string;
  role: IRoles;
}

export interface ILoginData {
  login: string;
  password: string;
}

export interface IRegisterData extends ILoginData {
  role: IRoles;
  name: string;
}
