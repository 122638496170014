import { useState, FormEvent } from "react";
import { useNavigate } from "react-router-dom";

import {
  Avatar,
  Button,
  CssBaseline,
  TextField,
  FormControlLabel,
  Checkbox,
  Link,
  Grid,
  Box,
  Typography,
  Container,
  IconButton,
  InputAdornment,
} from "@mui/material";

import { VisibilityOff, Visibility, LockOutlined } from "@mui/icons-material/";

import { Copyright } from "../../components";
import { useTypedSelector, useActions } from "../../hooks";
import { ILoginData } from "../../models/IUser";

export default function SignIn() {
  const navigate = useNavigate();

  const { loginUser } = useActions();
  const { isLoading } = useTypedSelector((store) => store.view);

  const [showPassword, setShowPassword] = useState<Boolean>(false);

  const [user, setUser] = useState({} as ILoginData);

  const handleChange = (e: any) => {
    const key = e.target.name;
    const value = e.target.value;
    setUser({ ...user, [key]: value });
  };

  const handleClickShowPassword = () => setShowPassword((show) => !show);
  const handleMouseDownPassword = (e: any) => e.preventDefault();

  const handleSubmit = async (event: FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    if (!user.login || !user.password) return;
    loginUser(user);
  };

  return (
    <Container maxWidth="xs" sx={{ marginTop: 0 }}>
      <CssBaseline />
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
        }}
      >
        <Avatar sx={{ m: 1, bgcolor: "primary.main" }}>
          <LockOutlined />
        </Avatar>
        <Typography component="h1" variant="h5">
          Вхід
        </Typography>
        <Box component="form" onSubmit={handleSubmit} noValidate sx={{ mt: 1 }}>
          <TextField
            margin="normal"
            required
            fullWidth
            label="Логін"
            name="login"
            autoComplete="login"
            autoFocus
            value={user["login"] || ""}
            onChange={handleChange}
          />
          <TextField
            margin="normal"
            required
            fullWidth
            name="password"
            label="Пароль"
            type={showPassword ? "text" : "password"}
            autoComplete="current-password"
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <IconButton
                    aria-label="toggle password visibility"
                    onClick={handleClickShowPassword}
                    onMouseDown={handleMouseDownPassword}
                    edge="end"
                  >
                    {showPassword ? <VisibilityOff /> : <Visibility />}
                  </IconButton>
                </InputAdornment>
              ),
            }}
            value={user["password"] || ""}
            onChange={handleChange}
          />

          <Button
            type="submit"
            fullWidth
            variant="contained"
            sx={{ mt: 3, mb: 2 }}
            disabled={isLoading}
          >
            Увійти
          </Button>
        </Box>
      </Box>
    </Container>
  );
}
