import { useEffect } from "react";
import { Link, useNavigate } from "react-router-dom";

import { Box, Button, Typography, useTheme } from "@mui/material";
import {
  DataGrid,
  GridValueGetterParams,
  GridRenderCellParams,
  GridToolbar,
  GridColDef,
  GridActionsCellItem,
  ukUA,
} from "@mui/x-data-grid";
import { Edit, Add, Delete, CheckCircle, Error } from "@mui/icons-material";
import { darken, lighten, styled } from "@mui/material/styles";

import { useActions, useTypedSelector } from "../../hooks";
import { format, parseISO } from "date-fns";

const getBackgroundColor = (color: string, mode: string) =>
  mode === "dark" ? darken(color, 0.7) : lighten(color, 0.7);

const getHoverBackgroundColor = (color: string, mode: string) =>
  mode === "dark" ? darken(color, 0.6) : lighten(color, 0.6);

const StyledDataGrid = styled(DataGrid)(({ theme }) => ({
  "& .super-app-theme--PartiallyFilled": {
    backgroundColor: getBackgroundColor(
      theme.palette.warning.main,
      theme.palette.mode
    ),
    "&:hover": {
      backgroundColor: getHoverBackgroundColor(
        theme.palette.warning.main,
        theme.palette.mode
      ),
    },
  },
}));

const Rewiews = () => {
  const { isAdmin } = useTypedSelector((state) => state.user);
  const { reviews } = useTypedSelector((state) => state.review);
  const { getReviews, showModal, deleteReview } = useActions();
  const theme: any = useTheme();
  const navigate = useNavigate();

  const handleDeleteReview = (id: number) => {
    showModal("Ви дійсно бажаєте видалити блок?", () => deleteReview(id));
  };

  const columns: GridColDef[] = [
    {
      field: "id",
      headerName: "id",
      minWidth: 75,
      flex: 0.1,
    },
    {
      field: "review",
      headerName: "Відгук",
      minWidth: 300,
      flex: 0.5,
    },
    {
      field: "request",
      headerName: "Відповідь",
      minWidth: 300,
      flex: 0.5,
      valueGetter: (params: GridValueGetterParams) => params.row.request ?? "-",
    },
    {
      field: "isPublished",
      headerName: "Опубліковано",
      minWidth: 150,
      flex: 0.1,
      renderCell: (params: GridRenderCellParams) =>
        params.row.isPublished === "1" ? (
          <CheckCircle color="success" />
        ) : (
          <Error color="warning" />
        ),
    },
    {
      field: "categoryName",
      headerName: "Категорія",
      minWidth: 150,
      flex: 0.2,
      valueGetter: (params: GridValueGetterParams) =>
        params.row.categoryName ?? "-",
    },
    {
      field: "date",
      headerName: "Дата",
      minWidth: 120,
      flex: 0.3,
      valueGetter: (params: GridValueGetterParams) =>
        params.row.date ? format(parseISO(params.row.date), "dd.MM.yyyy") : "-",
    },
    {
      field: "actions",
      type: "actions",
      headerName: "Дії",
      minWidth: 100,
      flex: 0.1,
      getActions: ({ id }) => {
        if (isAdmin) {
          return [
            <GridActionsCellItem
              icon={<Edit />}
              label="Edit"
              className="textPrimary"
              onClick={() => navigate("/reviews/" + id)}
              color="primary"
            />,
            <GridActionsCellItem
              icon={<Delete />}
              label="delet"
              className="textPrimary"
              onClick={() => handleDeleteReview(Number(id))}
              color="error"
            />,
          ];
        }
        return [];
      },
    },
  ];

  useEffect(() => {
    getReviews();
  }, []);

  return (
    <>
      <Box
        sx={{
          display: "flex",
          justifyContent: "flex-start",
          alignItems: "center",
        }}
      >
        <Typography variant="h2" color={"primary"} fontWeight="bold">
          Відгуки
        </Typography>
      </Box>
      <Box
        mt="40px"
        sx={{
          "& .MuiDataGrid-root": {
            border: "none",
          },
          "& .MuiDataGrid-cell": {
            borderBottom: "none",
          },
          "& .MuiDataGrid-columnHeaders": {
            backgroundColor: theme.palette.background.paper,
            color: theme.palette.primary[300],
            borderBottom: "none",
          },
          "& .MuiDataGrid-virtualScroller": {
            backgroundColor: theme.palette.background,
          },
          "& .MuiDataGrid-footerContainer": {
            backgroundColor: theme.palette.background.paper,
            color: theme.palette.primary[300],
            borderTop: "none",
          },
          "& .MuiDataGrid-toolbarContainer .MuiButton-text": {
            color: `${theme.palette.primary[300]} !important`,
          },
        }}
      >
        <StyledDataGrid
          localeText={ukUA.components.MuiDataGrid.defaultProps.localeText}
          rows={reviews || []}
          columns={columns}
          slots={{ toolbar: GridToolbar }}
          slotProps={{
            toolbar: {
              showQuickFilter: true,
            },
          }}
          disableRowSelectionOnClick
          initialState={{
            pagination: { paginationModel: { pageSize: 10 } },
          }}
          pageSizeOptions={[10, 15, 25]}
          disableColumnMenu
          getRowClassName={(params) =>
            params.row.isPublished === "0"
              ? `super-app-theme--PartiallyFilled`
              : ""
          }
        />
      </Box>
    </>
  );
};

export default Rewiews;
