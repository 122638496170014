import { MenuItem } from "@mui/material";
import { SelectChangeEvent } from "@mui/material/Select";
import { CustomizedSelect } from "../styled";

const CustomSelect = ({
  callback,
  value,
  list,
}: {
  callback: (value: any) => void;
  value: string;
  list: any[];
}) => {
  const handleChange = (event: SelectChangeEvent<string>) => {
    const newValue = event.target.value;
    callback(newValue);
  };

  return (
    <CustomizedSelect value={value} onChange={handleChange}>
      {list.map((item) => (
        <MenuItem key={item} value={item}>
          {item}
        </MenuItem>
      ))}
    </CustomizedSelect>
  );
};

export default CustomSelect;
