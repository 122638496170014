import { IReview } from "../IReview";

export interface ReviewsState {
  reviews: IReview[];
  review: IReview;
  isTouchedReview: boolean;
}

export enum ReviewsActionTypes {
  SET_REVIEWS = "SET_REVIEWS",
  SET_REVIEWS_ITEM = "SET_REVIEWS_ITEM",
  CHANGE_REVIEWS_ITEM = "CHANGE_REVIEWS_ITEM",
  PUBLIC_REVIEWS_ITEM = "PUBLIC_REVIEWS_ITEM",
  TOUCH_REVIEWS_ITEM = "TOUCH_REVIEWS_ITEM",
  DELETE_REVIEWS_ITEM = "DELETE_REVIEWS_ITEM",
}

interface SetReviewsAction {
  type: ReviewsActionTypes.SET_REVIEWS;
  payload: IReview[];
}
interface SetReviewsItemAction {
  type: ReviewsActionTypes.SET_REVIEWS_ITEM;
  payload: IReview;
}
interface ChangeReviewsItemAction {
  type: ReviewsActionTypes.CHANGE_REVIEWS_ITEM;
  payload: { name: string; value: any };
}
interface PublicReviewsItemAction {
  type: ReviewsActionTypes.PUBLIC_REVIEWS_ITEM;
}
interface TouchReviewsItemAction {
  type: ReviewsActionTypes.TOUCH_REVIEWS_ITEM;
}
interface DeleteReviewsItemAction {
  type: ReviewsActionTypes.DELETE_REVIEWS_ITEM;
  payload: number;
}

export type ReviewsAction =
  | SetReviewsAction
  | SetReviewsItemAction
  | ChangeReviewsItemAction
  | PublicReviewsItemAction
  | TouchReviewsItemAction
  | DeleteReviewsItemAction;
