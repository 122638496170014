import { useState, FormEvent, useMemo } from "react";

import {
  Avatar,
  Button,
  CssBaseline,
  TextField,
  Select,
  MenuItem,
  Grid,
  Box,
  Typography,
  Container,
  IconButton,
  InputAdornment,
  FormControl,
  InputLabel,
} from "@mui/material";

import { VisibilityOff, Visibility, LockOutlined } from "@mui/icons-material";

import {
  useActions,
  getAccessRolesByRole,
  useTypedSelector,
} from "../../hooks";
import { IRegisterData, IRoles } from "../../models/IUser";

export default function SignUp() {
  const { role } = useTypedSelector((store) => store.user);
  const { registerUser, showAlert } = useActions();

  const [user, setUser] = useState({
    role: IRoles.MANAGER,
  } as IRegisterData);

  const handleChange = (e: any) => {
    const key = e.target.name;
    const value = e.target.value;
    setUser({ ...user, [key]: value });
  };

  const [showPassword, setShowPassword] = useState<Boolean>(false);

  const handleClickShowPassword = () => setShowPassword((show) => !show);
  const handleMouseDownPassword = (e: any) => e.preventDefault();

  const handleSubmit = async (event: FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    if (!user.name || !user.login || !user.password || !user.role) {
      return showAlert("Заповніть усі необхнідні поля", "error");
    }
    registerUser(user, () =>
      setUser({ role: IRoles.MANAGER } as IRegisterData)
    );
  };

  const roles = useMemo(() => {
    return getAccessRolesByRole(role);
  }, []);

  return (
    <Container maxWidth="xs">
      <CssBaseline />
      <Box
        sx={{
          marginTop: 0,
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
        }}
      >
        <Avatar sx={{ m: 1, bgcolor: "primary.main" }}>
          <LockOutlined />
        </Avatar>
        <Typography component="h1" variant="h5">
          Створити новий аккаунт
        </Typography>
        <Box component="form" noValidate onSubmit={handleSubmit} sx={{ mt: 3 }}>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <TextField
                required
                autoComplete="given-name"
                name="name"
                fullWidth
                label="Ім'я"
                autoFocus
                value={user["name"] || ""}
                onChange={handleChange}
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                required
                fullWidth
                id="login"
                label="Логін"
                name="login"
                autoComplete="login"
                value={user["login"] || ""}
                onChange={handleChange}
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                required
                fullWidth
                name="password"
                label="Пароль"
                type={showPassword ? "text" : "password"}
                id="password"
                autoComplete="new-password"
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      <IconButton
                        aria-label="toggle password visibility"
                        onClick={handleClickShowPassword}
                        onMouseDown={handleMouseDownPassword}
                        edge="end"
                      >
                        {showPassword ? <VisibilityOff /> : <Visibility />}
                      </IconButton>
                    </InputAdornment>
                  ),
                }}
                value={user["password"] || ""}
                onChange={handleChange}
              />
            </Grid>
            <Grid item xs={12}>
              <FormControl sx={{ minWidth: "200px" }}>
                <InputLabel>Роль</InputLabel>
                <Select
                  label="Роль"
                  value={user["role"]}
                  onChange={handleChange}
                  name="role"
                >
                  {roles.map((role) => (
                    <MenuItem key={role} value={role}>
                      {role}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Grid>
          </Grid>
          <Button
            type="submit"
            fullWidth
            variant="contained"
            sx={{ mt: 3, mb: 2 }}
          >
            Створити
          </Button>
        </Box>
      </Box>
    </Container>
  );
}
