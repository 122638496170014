import { useState, MouseEvent } from "react";
import { Box, Typography, IconButton } from "@mui/material";

import { Logout, AccountCircle } from "@mui/icons-material";
import { FlexBetween } from "../";
import { useActions, useTypedSelector } from "../../hooks";

export default function UserMenu() {
  const { role, name } = useTypedSelector((state) => state.user);
  const { logoutUser } = useActions();

  const logout = () => logoutUser();
  return (
    <FlexBetween>
      <Box
        sx={{
          width: "32px",
          height: "32px",
        }}
      >
        <AccountCircle
          color="primary"
          sx={{ width: "100%", height: "100%", mr: 2 }}
        />
      </Box>
      <Box sx={{ ml: 1, mr: 2 }} textAlign="left">
        <Typography
          fontWeight="bold"
          fontSize="0.85rem"
          sx={{ color: "primary.100" }}
        >
          {name || "USER"}
        </Typography>
        <Typography
          fontSize="0.75rem"
          sx={{
            color: "primary.200",
            textTransform: "uppercase",
          }}
        >
          {role}
        </Typography>
      </Box>
      <IconButton
        onClick={logout}
        color="error"
        size="large"
        aria-label="logout"
      >
        <Logout fontSize="inherit" />
      </IconButton>
    </FlexBetween>
  );
}
