import { ICategory } from "./ICategory";

export interface IWork {
  id?: number;
  title: string;
  previewImage: string;
  date: Date | null;
  formatedDate: string;
  workDuration: string;
  workCost: number;
  categoryId: number;
  images: string[];
  blocks: IWorkBlock[];
  types: ICategory[];
  isPreview: boolean;
}

export interface IWorkPreview {
  id: number;
  title: string;
  workCost: number;
  workDuration: string;
  date: string | null;
  categoryName: string;
  isPreview: boolean;
}

export type IWorkError = {
  [key: string]: string | string[];
};

export enum IWorkBlockTypes {
  P = "P",
  UL = "UL",
}

export interface IWorkBlock {
  type: IWorkBlockTypes;
  text?: string;
  list?: string[];
}
