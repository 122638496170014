import { useEffect } from "react";
import { Title, WorksItemLayout } from "../../components";
import { useActions } from "../../hooks";

const WorksCreate = () => {
  const { setWorkDraft, getCategories } = useActions();

  useEffect(() => {
    getCategories();
    setWorkDraft();
  }, []);

  return (
    <>
      <Title title={"Створіть новий елемент"} subtitle="" />
      <WorksItemLayout />
    </>
  );
};
export default WorksCreate;
