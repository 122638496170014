export const themeSettings = (mode) => {
  return {
    palette: {
      mode: mode,
      primary: {
        // yellow
        main: "#FFBD00",
        50: "#fff7e0",
        100: "#ffeab2",
        200: "#ffdd80",
        300: "#ffd04c",
        400: "#ffc525",
        500: "#ffbb00",
        600: "#ffad00",
        700: "#ff9a00",
        800: "#fe8903",
        900: "#fe6806",
        light: "rgb(255, 202, 51)",
        dark: "rgb(178, 132, 0)",
        contrastText: "rgba(0, 0, 0, 0.87)",
      },

      secondary: {
        // purple
        main: "#9C27B0",
        50: "#f3e5f5",
        100: "#e1bee7",
        200: "#ce93d8",
        300: "#ba68c8",
        400: "#ab47bc",
        500: "#9b27b0",
        600: "#8d24aa",
        700: "#7a1fa2",
        800: "#691b9a",
        900: "#49148c",
        light: "rgb(175, 82, 191)",
        dark: "rgb(109, 27, 123)",
        contrastText: "#ffffff",
      },
      ...(mode === "dark"
        ? {
            background: {
              paper: "#161616",
            },
          }
        : {
            background: {
              paper: "#F5F5F5",
            },
          }),
    },
    typography: {
      fontFamily: ["Inter", "sans-serif"].join(","),
      fontSize: 12,
      h1: {
        fontFamily: ["Inter", "sans-serif"].join(","),
        fontSize: 40,
      },
      h2: {
        fontFamily: ["Inter", "sans-serif"].join(","),
        fontSize: 32,
      },
      h3: {
        fontFamily: ["Inter", "sans-serif"].join(","),
        fontSize: 24,
      },
      h4: {
        fontFamily: ["Inter", "sans-serif"].join(","),
        fontSize: 20,
      },
      h5: {
        fontFamily: ["Inter", "sans-serif"].join(","),
        fontSize: 16,
      },
      h6: {
        fontFamily: ["Inter", "sans-serif"].join(","),
        fontSize: 14,
      },
    },
  };
};
