import { useEffect } from "react";
import { Link, useNavigate } from "react-router-dom";

import { Box, Button, Typography, useTheme } from "@mui/material";
import {
  DataGrid,
  GridValueGetterParams,
  GridToolbar,
  GridColDef,
  GridActionsCellItem,
  GridRenderCellParams,
  ukUA,
} from "@mui/x-data-grid";
import { Edit, Add, Delete, CheckCircle } from "@mui/icons-material";
import { darken, lighten, styled } from "@mui/material/styles";

import { useActions, useTypedSelector } from "../../hooks";
import { format, parseISO } from "date-fns";

const getBackgroundColor = (color: string, mode: string) =>
  mode === "dark" ? darken(color, 0.7) : lighten(color, 0.7);

const getHoverBackgroundColor = (color: string, mode: string) =>
  mode === "dark" ? darken(color, 0.6) : lighten(color, 0.6);

const StyledDataGrid = styled(DataGrid)(({ theme }) => ({
  "& .super-app-theme--success": {
    backgroundColor: getBackgroundColor(
      theme.palette.success.main,
      theme.palette.mode
    ),
    "&:hover": {
      backgroundColor: getHoverBackgroundColor(
        theme.palette.success.main,
        theme.palette.mode
      ),
    },
  },
}));

const Works = () => {
  const { isAdmin } = useTypedSelector((state) => state.user);
  const { worksPreview } = useTypedSelector((state) => state.work);
  const { getWorksPreview, showModal, deleteWork } = useActions();
  const theme: any = useTheme();
  const navigate = useNavigate();

  const handleDeleteReview = (id: number) => {
    showModal("Ви дійсно бажаєте видалити блок?", () => deleteWork(id));
  };

  const columns: GridColDef[] = [
    {
      field: "id",
      headerName: "id",
      minWidth: 75,
      flex: 0.1,
    },
    {
      field: "title",
      headerName: "Назва",
      minWidth: 300,
      flex: 1,
    },
    {
      field: "categoryName",
      headerName: "Категорія",
      minWidth: 150,
      flex: 0.2,
    },
    {
      field: "workCost",
      headerName: "Вартість проекту (у грн)",
      minWidth: 200,
      flex: 0.3,
    },
    {
      field: "workDuration",
      headerName: "Тривалість",
      minWidth: 120,
      flex: 0.3,
    },
    {
      field: "date",
      headerName: "Дата",
      minWidth: 120,
      flex: 0.3,
      valueGetter: (params: GridValueGetterParams) =>
        params.row.date ? format(parseISO(params.row.date), "dd.MM.yyyy") : "-",
    },
    {
      field: "isPreview",
      headerName: "Передперегляд",
      minWidth: 150,
      flex: 0.1,
      renderCell: (params: GridRenderCellParams) =>
        params.row.isPreview === "1" ? <CheckCircle color="success" /> : <></>,
    },
    {
      field: "actions",
      type: "actions",
      headerName: "Дії",
      minWidth: 100,
      flex: 0.1,
      getActions: ({ id }) => {
        if (isAdmin) {
          return [
            <GridActionsCellItem
              icon={<Edit />}
              label="Edit"
              className="textPrimary"
              onClick={() => navigate("/works/" + id)}
              color="primary"
            />,
            <GridActionsCellItem
              icon={<Delete />}
              label="delet"
              className="textPrimary"
              onClick={() => handleDeleteReview(Number(id))}
              color="error"
            />,
          ];
        }
        return [];
      },
    },
  ];

  useEffect(() => {
    getWorksPreview();
  }, []);

  return (
    <>
      <Box
        sx={{
          display: "flex",
          justifyContent: "flex-start",
          alignItems: "center",
        }}
      >
        <Typography variant="h2" color={"primary"} fontWeight="bold">
          Роботи
        </Typography>
        <Link to="/works/create">
          <Button
            size="large"
            sx={{ ml: 4 }}
            color="success"
            variant="contained"
            endIcon={<Add fontSize="inherit" />}
          >
            Створити
          </Button>
        </Link>
      </Box>
      <Box
        mt="40px"
        min-height="50vh"
        sx={{
          "& .MuiDataGrid-root": {
            border: "none",
          },
          "& .MuiDataGrid-cell": {
            borderBottom: "none",
          },
          "& .MuiDataGrid-columnHeaders": {
            backgroundColor: theme.palette.background.paper,
            color: theme.palette.primary[300],
            borderBottom: "none",
          },
          "& .MuiDataGrid-virtualScroller": {
            backgroundColor: theme.palette.background,
          },
          "& .MuiDataGrid-footerContainer": {
            backgroundColor: theme.palette.background.paper,
            color: theme.palette.primary[300],
            borderTop: "none",
          },
          "& .MuiDataGrid-toolbarContainer .MuiButton-text": {
            color: `${theme.palette.primary[300]} !important`,
          },
        }}
      >
        <StyledDataGrid
          localeText={ukUA.components.MuiDataGrid.defaultProps.localeText}
          rows={worksPreview || []}
          columns={columns}
          slots={{ toolbar: GridToolbar }}
          slotProps={{
            toolbar: {
              showQuickFilter: true,
            },
          }}
          disableRowSelectionOnClick
          initialState={{
            pagination: { paginationModel: { pageSize: 10 } },
          }}
          pageSizeOptions={[10, 15, 25]}
          disableColumnMenu
          getRowClassName={(params) =>
            params.row.isPreview === "1" ? `super-app-theme--success` : ""
          }
        />
      </Box>
    </>
  );
};
export default Works;
