import { IRoles } from "../models/IUser";
import { IWorkBlockTypes } from "../models/IWork";

export const localStorageToken = "token";

export const roleScore: Record<IRoles, number> = {
  MANAGER: 0,
  BLOGGER: 0,
  HELPER: 1,
  ADMIN: 2,
  OWNER: 3,
  GOD: 4,
};

export const WORKS_BLOCK_TYPES: IWorkBlockTypes[] = [
  IWorkBlockTypes.P,
  IWorkBlockTypes.UL,
];
