import { useMemo } from "react";
import { Navigate, Route, Routes } from "react-router-dom";

import {
  Dashboard as DashBoardIcon,
  People as PeopleIcon,
  Layers,
  PersonAdd,
  HomeRepairService,
  Category,
  RecordVoiceOver,
  OndemandVideo,
} from "@mui/icons-material/";
import { useTypedSelector } from "./hooks";
import { IRoles } from "./models/IUser";
import { IRoute } from "./models/IRoutes";
import {
  Categories,
  DashBoard,
  SignIn,
  SignUp,
  Layout,
  People,
  Reviews,
  ReviewsSingle,
  VideoReviews,
  Works,
  WorksCreate,
  WorksSingle,
} from "./pages";
import { ProtectedRoute } from "./components/ProtectedRoute";

export const LinksList: IRoute[] = [
  // {
  //   text: "Статистика",
  //   path: "dashboard",
  //   icon: <DashBoardIcon />,
  //   element: <DashBoard />,
  //   accessRolesWithAdmins: [IRoles.MANAGER],
  // },
  {
    text: "Люди",
    path: "people",
    icon: <PeopleIcon />,
    element: <People />,
  },
  // {
  //   text: "Категорії",
  //   path: "categories",
  //   icon: <Category />,
  //   element: <Categories />,
  //   isAdminRequire: true,
  // },
  {
    text: "Відгуки",
    path: "reviews",
    icon: <RecordVoiceOver />,
    element: <Reviews />,
    isAdminRequire: true,
  },
  // {
  //   text: "Відео відгуки",
  //   path: "video-reviews",
  //   icon: <OndemandVideo />,
  //   element: <VideoReviews />,
  //   isAdminRequire: true,
  // },
  {
    text: "Роботи",
    path: "works",
    icon: <HomeRepairService />,
    element: <Works />,
    isAdminRequire: true,
  },
  {
    text: "Створити аккаунт",
    path: "user-create",
    element: <SignUp />,
    icon: <PersonAdd />,
    accessRoles: [IRoles.OWNER, IRoles.ADMIN],
  },
];

export const RoutesList: IRoute[] = [
  ...LinksList,
  // {
  //   path: "reviews/video",
  //   element: <ReviewsVideo />,
  //   isAdminRequire: true,
  // },
  {
    path: "reviews/:id",
    element: <ReviewsSingle />,
    isAdminRequire: true,
  },
  {
    path: "works/create",
    element: <WorksCreate />,
    isAdminRequire: true,
  },
  {
    path: "works/:id",
    element: <WorksSingle />,
    isAdminRequire: true,
  },
];

export default function useRoutes() {
  const { id } = useTypedSelector((state) => state.user);
  const { isInitLoading } = useTypedSelector((state) => state.view);

  const isAuth = useMemo(() => !!id, [id]);

  return (
    <Routes>
      <Route element={<Layout />}>
        {!isInitLoading && (
          <>
            {isAuth ? (
              <>
                {RoutesList.map((route, index) => {
                  const { path, element } = route;
                  return (
                    <Route
                      key={index}
                      path={"/" + path}
                      element={
                        <ProtectedRoute key={index} route={route}>
                          {element}
                        </ProtectedRoute>
                      }
                    />
                  );
                })}

                <Route path="*" element={<Navigate to="people" replace />} />
              </>
            ) : (
              <>
                <Route path="/sign-in" element={<SignIn />} />
                <Route path="/forgot" element={<SignIn />} />
                <Route path="*" element={<Navigate to="/sign-in" replace />} />
              </>
            )}
          </>
        )}
      </Route>
    </Routes>
  );
}
