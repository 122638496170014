import { IAlert, IAlertType } from "../IAlert";
import { IModal } from "../IModal";

export interface ViewState extends IAlert, IModal {
  isLoading: boolean;
  isInitLoading: boolean;
  isLoadingVar: boolean;
}

export enum ViewActionTypes {
  SET_LOADING = "SET_LOADING",
  REMOVE_INIT_LOADING = "REMOVE_INIT_LOADING",
  SHOW_ALERT = "SHOW_ALERT",
  HIDE_ALERT = "HIDE_ALERT",
  SHOW_MODAL = "SHOW_MODAL",
  HIDE_MODAL = "HIDE_MODAL",
  SET_LOADING_VAR = "SET_LOADING_VAR",
}

interface SetLoadingViewAction {
  type: ViewActionTypes.SET_LOADING;
  payload: boolean;
}
interface RemoveInitLoadingViewAction {
  type: ViewActionTypes.REMOVE_INIT_LOADING;
}
interface ShowAlertViewAction {
  type: ViewActionTypes.SHOW_ALERT;
  payload: {
    alertType: IAlertType;
    alertText: string;
  };
}
interface HideAlertViewAction {
  type: ViewActionTypes.HIDE_ALERT;
}
interface ShowModalViewAction {
  type: ViewActionTypes.SHOW_MODAL;
  payload: {
    modalText: string;
    modalCallback: () => void;
  };
}
interface HideModalViewAction {
  type: ViewActionTypes.HIDE_MODAL;
}
interface SetLoadingVarViewAction {
  type: ViewActionTypes.SET_LOADING_VAR;
  payload: boolean;
}

export type ViewAction =
  | SetLoadingViewAction
  | RemoveInitLoadingViewAction
  | ShowAlertViewAction
  | HideAlertViewAction
  | ShowModalViewAction
  | HideModalViewAction
  | SetLoadingVarViewAction;
