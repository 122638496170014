import { Dispatch } from "redux";
import { IWork, IWorkBlockTypes } from "../../models/IWork";
import { ViewAction, ViewActionTypes } from "../../models/redux/view";
import { WorksAction, WorksActionTypes } from "../../models/redux/work";
import {
  CategoriesAction,
  CategoriesActionTypes,
} from "../../models/redux/category";
import FilesService from "../../services/FilesService";
import WorkService from "../../services/WorkService";
import { format } from "date-fns";

export const getWorksPreview = () => {
  return async (dispatch: Dispatch<WorksAction | ViewAction>) => {
    dispatch({ type: ViewActionTypes.SET_LOADING, payload: true });
    try {
      const response = await WorkService.getWorksPreview();
      dispatch({
        type: WorksActionTypes.SET_WORKS_PREVIEW,
        payload: response.data,
      });
    } catch (e: any) {
      const alertText = e?.response?.data?.message ?? "Упс, щось пішло не так!";
      dispatch({
        type: ViewActionTypes.SHOW_ALERT,
        payload: { isShowAlert: true, alertText, alertType: "error" },
      });
    }
    dispatch({ type: ViewActionTypes.SET_LOADING, payload: false });
  };
};

export const setWorkDraft = () => {
  return async (
    dispatch: Dispatch<WorksAction | ViewAction | CategoriesAction>
  ) => {
    dispatch({ type: ViewActionTypes.SET_LOADING, payload: true });
    try {
      const response = await WorkService.getWorkDraft();
      let work = response.data;

      if (!work) {
        const response = await WorkService.createWorkDraft();
        work = response.data;
      }

      if (work["date"]) {
        work["date"] = new Date(work.date);
      }

      if (work["categoryId"]) {
        dispatch({
          type: CategoriesActionTypes.SET_CHILDREN_BY_CATEGORY,
          payload: work["categoryId"],
        });
      }

      dispatch({
        type: WorksActionTypes.SET_WORK,
        payload: work,
      });
    } catch (e: any) {
      const alertText = e?.response?.data?.message ?? "Упс, щось пішло не так!";
      dispatch({
        type: ViewActionTypes.SHOW_ALERT,
        payload: { isShowAlert: true, alertText, alertType: "error" },
      });
    }
    dispatch({ type: ViewActionTypes.SET_LOADING, payload: false });
  };
};
export const setWorkById = (id: string) => {
  return async (
    dispatch: Dispatch<WorksAction | ViewAction | CategoriesAction>
  ) => {
    dispatch({ type: ViewActionTypes.SET_LOADING, payload: true });
    try {
      const response = await WorkService.getWorkById(id);
      const work = response.data;

      if (work["date"]) {
        work["date"] = new Date(work.date);
      }

      if (work["categoryId"]) {
        dispatch({
          type: CategoriesActionTypes.SET_CHILDREN_BY_CATEGORY,
          payload: work["categoryId"],
        });
      }

      dispatch({
        type: WorksActionTypes.SET_WORK,
        payload: work,
      });
    } catch (e: any) {
      const alertText = e?.response?.data?.message ?? "Упс, щось пішло не так!";
      dispatch({
        type: ViewActionTypes.SHOW_ALERT,
        payload: { isShowAlert: true, alertText, alertType: "error" },
      });
    }
    dispatch({ type: ViewActionTypes.SET_LOADING, payload: false });
  };
};
export const changeWork = (name: string, value: any) => {
  return (dispatch: Dispatch<WorksAction>) => {
    dispatch({
      type: WorksActionTypes.CHANGE_WORK,
      payload: { name, value },
    });
  };
};
export const uploadWorkCover = (workId: number | undefined, formData: any) => {
  return async (dispatch: Dispatch<WorksAction | ViewAction>) => {
    dispatch({ type: ViewActionTypes.SET_LOADING, payload: true });
    try {
      const imagesResponse = await FilesService.uploadImages(formData);
      const cover = imagesResponse.data[0];

      if (cover && workId) {
        await WorkService.addCover(workId, cover);
      }

      dispatch({
        type: WorksActionTypes.UPLOAD_WORK_COVER,
        payload: cover,
      });
    } catch (e: any) {
      const alertText = e?.response?.data?.message ?? "Упс, щось пішло не так!";
      dispatch({
        type: ViewActionTypes.SHOW_ALERT,
        payload: { isShowAlert: true, alertText, alertType: "error" },
      });
    }
    dispatch({ type: ViewActionTypes.SET_LOADING, payload: false });
  };
};
export const uploadWorkImages = (workId: number | undefined, formData: any) => {
  return async (dispatch: Dispatch<WorksAction | ViewAction>) => {
    dispatch({ type: ViewActionTypes.SET_LOADING, payload: true });
    try {
      const imagesResponse = await FilesService.uploadImages(formData);
      const images = imagesResponse.data;

      if (images && workId) {
        await WorkService.addImagesPaths(workId, images);
      }

      dispatch({
        type: WorksActionTypes.UPLOAD_WORK_IMAGES,
        payload: images,
      });
    } catch (e: any) {
      console.log(e);
      const alertText = e?.response?.data?.message ?? "Упс, щось пішло не так!";
      dispatch({
        type: ViewActionTypes.SHOW_ALERT,
        payload: { isShowAlert: true, alertText, alertType: "error" },
      });
    }
    dispatch({ type: ViewActionTypes.SET_LOADING, payload: false });
  };
};
export const deleteWorkCover = (workId: number | undefined, path: string) => {
  return async (dispatch: Dispatch<WorksAction | ViewAction>) => {
    dispatch({ type: ViewActionTypes.SET_LOADING, payload: true });
    try {
      if (path) {
        await FilesService.deleteImage(path);

        if (workId) {
          await WorkService.deleteWorkCover(workId, path);
        }
      }

      dispatch({
        type: WorksActionTypes.DELETE_WORK_COVER,
      });
    } catch (e: any) {
      const alertText = e?.response?.data?.message ?? "Упс, щось пішло не так!";
      dispatch({
        type: ViewActionTypes.SHOW_ALERT,
        payload: { isShowAlert: true, alertText, alertType: "error" },
      });
    }
    dispatch({ type: ViewActionTypes.SET_LOADING, payload: false });
  };
};
export const deleteWorkImage = (
  workId: number | undefined,
  imageIndex: number,
  path: string
) => {
  return async (dispatch: Dispatch<WorksAction | ViewAction>) => {
    dispatch({ type: ViewActionTypes.SET_LOADING, payload: true });
    try {
      if (path) {
        await FilesService.deleteImage(path);

        if (workId) {
          await WorkService.deleteWorkImage(workId, path);
        }
      }

      dispatch({
        type: WorksActionTypes.DELETE_WORK_IMAGE,
        payload: imageIndex,
      });
    } catch (e: any) {
      const alertText = e?.response?.data?.message ?? "Упс, щось пішло не так!";
      dispatch({
        type: ViewActionTypes.SHOW_ALERT,
        payload: { isShowAlert: true, alertText, alertType: "error" },
      });
    }
    dispatch({ type: ViewActionTypes.SET_LOADING, payload: false });
  };
};

export const createWorkBlock = (
  workId: number | undefined,
  newType: IWorkBlockTypes
) => {
  return async (dispatch: Dispatch<WorksAction | ViewAction>) => {
    dispatch({ type: ViewActionTypes.SET_LOADING, payload: true });
    try {
      if (workId) {
        await WorkService.createBlock(workId, newType);
      }
      dispatch({
        type: WorksActionTypes.CREATE_WORK_BLOCK,
        payload: newType,
      });
      dispatch({
        type: ViewActionTypes.SHOW_ALERT,
        payload: {
          isShowAlert: true,
          alertText: "Новий блок створено",
          alertType: "success",
        },
      });
    } catch (e: any) {
      const alertText = e?.response?.data?.message ?? "Упс, щось пішло не так!";
      dispatch({
        type: ViewActionTypes.SHOW_ALERT,
        payload: { isShowAlert: true, alertText, alertType: "error" },
      });
    }
    dispatch({ type: ViewActionTypes.SET_LOADING, payload: false });
  };
};
export const deleteWorkBlock = (
  workId: number | undefined,
  blockIndex: number
) => {
  return async (dispatch: Dispatch<WorksAction | ViewAction>) => {
    dispatch({ type: ViewActionTypes.SET_LOADING, payload: true });
    try {
      if (workId) {
        await WorkService.deleteBlock(workId, blockIndex);
      }
      dispatch({
        type: WorksActionTypes.DELETE_WORK_BLOCK,
        payload: blockIndex,
      });
      dispatch({
        type: ViewActionTypes.SHOW_ALERT,
        payload: {
          isShowAlert: true,
          alertText: "Блок видалено",
          alertType: "success",
        },
      });
    } catch (e: any) {
      const alertText = e?.response?.data?.message ?? "Упс, щось пішло не так!";
      dispatch({
        type: ViewActionTypes.SHOW_ALERT,
        payload: { isShowAlert: true, alertText, alertType: "error" },
      });
    }
    dispatch({ type: ViewActionTypes.SET_LOADING, payload: false });
  };
};
export const changeWorkBlock = (
  e: React.ChangeEvent<HTMLInputElement>,
  blockIndex: number
) => {
  return (dispatch: Dispatch<WorksAction | ViewAction>) => {
    const key = e.target.name;
    const value = e.target.value;
    dispatch({
      type: WorksActionTypes.CHANGE_WORK_BLOCK,
      payload: { blockIndex, key, value },
    });
  };
};
export const changeWorkBlocksPosition = (
  workId: number | undefined,
  blockIndex: number,
  direction: 1 | -1
) => {
  return async (dispatch: Dispatch<WorksAction | ViewAction>) => {
    dispatch({ type: ViewActionTypes.SET_LOADING, payload: true });
    try {
      if (workId) {
        await WorkService.updateBlocksPosition(workId, blockIndex, direction);
      }
      dispatch({
        type: WorksActionTypes.CHANGE_WORK_BLOCK_POSITION,
        payload: { blockIndex, direction },
      });
    } catch (e: any) {
      const alertText = e?.response?.data?.message ?? "Упс, щось пішло не так!";
      dispatch({
        type: ViewActionTypes.SHOW_ALERT,
        payload: { isShowAlert: true, alertText, alertType: "error" },
      });
    }
    dispatch({ type: ViewActionTypes.SET_LOADING, payload: false });
  };
};

export const createWorkBlockListItem = (
  workId: number | undefined,
  blockIndex: number
) => {
  return async (dispatch: Dispatch<WorksAction | ViewAction>) => {
    dispatch({ type: ViewActionTypes.SET_LOADING, payload: true });
    try {
      if (workId) {
        await WorkService.createBlogBlockListItem(workId, blockIndex);
      }

      dispatch({
        type: WorksActionTypes.CREATE_WORK_BLOCK_LIST_ITEM,
        payload: blockIndex,
      });
    } catch (e: any) {
      const alertText = e?.response?.data?.message ?? "Упс, щось пішло не так!";
      dispatch({
        type: ViewActionTypes.SHOW_ALERT,
        payload: { isShowAlert: true, alertText, alertType: "error" },
      });
    }
    dispatch({ type: ViewActionTypes.SET_LOADING, payload: false });
  };
};
export const deleteWorkBlockListItem = (
  workId: number | undefined,
  blockIndex: number,
  listItemIndex: number
) => {
  return async (dispatch: Dispatch<WorksAction | ViewAction>) => {
    dispatch({ type: ViewActionTypes.SET_LOADING, payload: true });
    try {
      if (workId) {
        await WorkService.deleteBlogBlockListItem(
          workId,
          blockIndex,
          listItemIndex
        );
      }

      dispatch({
        type: WorksActionTypes.DELETE_WORK_BLOCK_LIST_ITEM,
        payload: { blockIndex, listItemIndex },
      });
    } catch (e: any) {
      const alertText = e?.response?.data?.message ?? "Упс, щось пішло не так!";
      dispatch({
        type: ViewActionTypes.SHOW_ALERT,
        payload: { isShowAlert: true, alertText, alertType: "error" },
      });
    }
    dispatch({ type: ViewActionTypes.SET_LOADING, payload: false });
  };
};
export const changeWorkBlockListItem = (
  blockIndex: number,
  listItemIndex: number,
  value: string
) => {
  return (dispatch: Dispatch<WorksAction>) => {
    dispatch({
      type: WorksActionTypes.CHANGE_WORK_BLOCK_LIST_ITEM,
      payload: { blockIndex, listItemIndex, value },
    });
  };
};

export const createWork = (work: IWork) => {
  return async (dispatch: Dispatch<WorksAction | ViewAction>) => {
    dispatch({ type: ViewActionTypes.SET_LOADING, payload: true });
    try {
      const { date } = work;

      work["formatedDate"] = format(date ?? new Date(), "yyyy-MM-dd");
      await WorkService.createWork(work);

      dispatch({
        type: WorksActionTypes.CREATE_WORK,
      });
    } catch (e: any) {
      const alertText = e?.response?.data?.message ?? "Упс, щось пішло не так!";
      dispatch({
        type: ViewActionTypes.SHOW_ALERT,
        payload: { isShowAlert: true, alertText, alertType: "error" },
      });
    }
    dispatch({ type: ViewActionTypes.SET_LOADING, payload: false });
  };
};
export const touchWork = () => {
  return (dispatch: Dispatch<WorksAction>) => {
    dispatch({
      type: WorksActionTypes.TOUCH_WORK,
    });
  };
};
export const deleteWork = (id: number) => {
  return async (dispatch: Dispatch<WorksAction | ViewAction>) => {
    dispatch({ type: ViewActionTypes.SET_LOADING, payload: true });
    try {
      await WorkService.deleteWork(id);

      dispatch({
        type: WorksActionTypes.DELETE_WORK,
        payload: id,
      });
    } catch (e: any) {
      console.log(e);

      const alertText = e?.response?.data?.message ?? "Упс, щось пішло не так!";
      dispatch({
        type: ViewActionTypes.SHOW_ALERT,
        payload: { isShowAlert: true, alertText, alertType: "error" },
      });
    }
    dispatch({ type: ViewActionTypes.SET_LOADING, payload: false });
  };
};
