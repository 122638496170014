import { ChangeEvent, useEffect, useState, useMemo } from "react";
import { Button, Box, IconButton } from "@mui/material";
import { API_FILES_URL } from "../../http";
import { HighlightOff } from "@mui/icons-material";
import { useActions, useTypedSelector } from "../../hooks";

export default function InputFile({
  multiple = false,
  label,
  name,
  addBlockImage,
  value,
  validate,
  handleDeleteFile,
  isTouched = false,
}: {
  multiple?: boolean;
  label: string;
  name: string;
  value: string | string[];
  addBlockImage: any;
  handleDeleteFile?: any;
  validate?: any[];
  isTouched?: boolean;
}) {
  const { isAdmin } = useTypedSelector((state) => state.user);
  const [isError, setIsError] = useState(false);

  const handleFileChange = (e: ChangeEvent<HTMLInputElement>) => {
    const files = e.target.files;
    if (!files) {
      return;
    }

    const newFiles = Array.from(files);

    const data = new FormData();
    newFiles.forEach((file, i) => {
      data.append(`file-${i}`, file, file.name);
    });

    addBlockImage(data);
  };

  const editAccess = useMemo(() => {
    if (!isAdmin) {
      return false;
    }
    return true;
  }, [isAdmin]);

  useEffect(() => {
    if (!isTouched) {
      setIsError(false);
      return;
    }
    if (!validate || validate.length <= 0) return;

    let newIsError = false;

    for (let i = 0; i < validate.length; i++) {
      const item = validate[i];
      const errorTextOrUndefined = item(value);
      if (errorTextOrUndefined) {
        newIsError = errorTextOrUndefined;
        break;
      }
    }

    setIsError(newIsError);
  }, [isTouched, value]);

  if (!editAccess) return <img alt="block" />;

  return (
    <Box>
      {multiple && Array.isArray(value) && value.length > 0 && (
        <Box
          mb={3}
          display="grid"
          gridTemplateColumns="repeat(12, 1fr)"
          gap={2}
        >
          {value.map((image, imageIndex) => (
            <Box key={image} gridColumn="span 6" sx={{ position: "relative" }}>
              <Box
                sx={{
                  display: "block",
                  paddingBottom: "60%",
                  height: 0,
                  position: "relative",
                }}
              >
                <img
                  style={{
                    position: "absolute",
                    top: 0,
                    right: 0,
                    bottom: 0,
                    left: 0,
                    objectFit: "cover",
                    width: "100%",
                    height: "100%",
                    display: "block",
                  }}
                  src={API_FILES_URL + image}
                  alt="#"
                />
              </Box>
              <IconButton
                onClick={() => handleDeleteFile(imageIndex, image)}
                sx={{
                  position: "absolute",
                  top: 0,
                  right: 0,
                }}
                aria-label="delete"
                size="medium"
                color="error"
              >
                <HighlightOff fontSize="large" />
              </IconButton>
            </Box>
          ))}
        </Box>
      )}

      {!multiple && value && (
        <Box sx={{ position: "relative" }}>
          <Box sx={{ position: "relative", height: 0, paddingBottom: "100%" }}>
            <img
              style={{
                position: "absolute",
                top: 0,
                right: 0,
                bottom: 0,
                left: 0,
                objectFit: "cover",
                width: "100%",
                height: "100%",
                display: "block",
              }}
              src={API_FILES_URL + value}
              alt="#"
            />
          </Box>
          <IconButton
            onClick={() => handleDeleteFile(value)}
            sx={{
              position: "absolute",
              top: 0,
              right: 0,
            }}
            aria-label="delete"
            size="medium"
            color="error"
          >
            <HighlightOff fontSize="large" />
          </IconButton>
        </Box>
      )}

      {((!multiple && !value) || multiple) && (
        <Button
          sx={{ width: "100%" }}
          variant="outlined"
          color={!!isError ? "error" : "primary"}
          component="label"
        >
          {label}
          <input
            type="file"
            accept="image/*"
            hidden
            onChange={handleFileChange}
            multiple={multiple}
          />
        </Button>
      )}
      {!!isError && (
        <Box
          sx={{
            color: "error.main",
            fontSize: "0.6428571428571428rem",
            lineHeight: "1.66",
            textAlign: "left",
            marginTop: "3px",
            marginRight: "14px",
            marginBottom: "0",
            marginLeft: "14px",
          }}
        >
          {isError}
        </Box>
      )}
    </Box>
  );
}
