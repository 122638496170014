import { roleScore } from "../constants";
import { IRoles } from "../models/IUser"

const getAccessByRole = (role: IRoles, compareRole: IRoles | null = null): boolean => {
  
    if (compareRole) {
        if (roleScore[role] > roleScore[compareRole]) {
            return true;
        }
        return false;
    }

    switch (role) {
        case IRoles.GOD:
        case IRoles.OWNER:
        case IRoles.ADMIN:
        case IRoles.HELPER:
            return true
        default:
            return false
    }
}
 export default getAccessByRole
 