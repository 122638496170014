import { Dispatch } from "redux";
import { IAlertType } from "../../models/IAlert";
import { ViewAction, ViewActionTypes } from "../../models/redux/view";

export const setLoading = (isLoading: boolean) => {
  return (dispatch: Dispatch<ViewAction>) => {
    dispatch({ type: ViewActionTypes.SET_LOADING, payload: isLoading });
  };
};
export const setInitLoading = () => {
  return (dispatch: Dispatch<ViewAction>) => {
    dispatch({ type: ViewActionTypes.REMOVE_INIT_LOADING });
  };
};
export const setLoadingVar = (isLoadingVar: boolean) => {
  return (dispatch: Dispatch<ViewAction>) => {
    dispatch({ type: ViewActionTypes.SET_LOADING_VAR, payload: isLoadingVar });
  };
};

export const showAlert = (alertText: string = "", alertType: IAlertType) => {
  return (dispatch: Dispatch<ViewAction>) => {
    dispatch({
      type: ViewActionTypes.SHOW_ALERT,
      payload: { alertText, alertType },
    });
  };
};
export const closeAlert = () => {
  return (dispatch: Dispatch<ViewAction>) => {
    dispatch({ type: ViewActionTypes.HIDE_ALERT });
  };
};

export const showModal = (modalText: string = "", callback: () => void) => {
  return (dispatch: Dispatch<ViewAction>) => {
    const modalCallback = () => {
      callback();
      dispatch({ type: ViewActionTypes.HIDE_MODAL });
    };
    dispatch({
      type: ViewActionTypes.SHOW_MODAL,
      payload: { modalText, modalCallback },
    });
  };
};
export const closeModal = () => {
  return (dispatch: Dispatch<ViewAction>) => {
    dispatch({ type: ViewActionTypes.HIDE_MODAL });
  };
};
