import {
  CategoriesAction,
  CategoriesActionTypes,
  CategoriesState,
} from "../../models/redux/category";

const initialState: CategoriesState = {
  categories: [],
  mainCategories: [],
  childrenByCategory: [],
};

export const categoryReducer = (
  state = initialState,
  action: CategoriesAction
): CategoriesState => {
  switch (action.type) {
    case CategoriesActionTypes.SET_CATEGORIES: {
      const categories = action.payload;
      const mainCategories = categories.filter(
        (item) => Number(item.parent_id) === 0
      );
      return { ...state, categories, mainCategories };
    }
    case CategoriesActionTypes.SET_MAIN_CATEGORIES:
      return { ...state, mainCategories: action.payload };
    case CategoriesActionTypes.SET_CHILDREN_BY_CATEGORY: {
      const categoryId = action.payload;
      const childrenByCategory = state.categories.filter(
        (item) => Number(item.parent_id) === Number(categoryId)
      );

      return {
        ...state,
        childrenByCategory,
      };
    }
    default:
      return state;
  }
};
